import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { getContainersList } from "../../services/container";
import {
  formattedFillingDelivery,
  formattedFillingDeliveryColor,
  formattedTypeContainer,
} from "../../components/formatted/FormattedText";
import SkeletonLoader from "../../components/utils/SkeletonLoader";
import { useTranslation } from "react-i18next";
import MultipleLineAndBarChart from "../../components/charts/MultipleLineAndBarChart";

export const DashboardGaugeContainer = ({
  datasetsFillingDelivery,
  setFillingRateDelivery,
  arrayFillingDelivery,
  setArrayFillingDelivery,
  typeContainersDelivery,
  countContainers,
  setCountContainers,
}) => {
  const { t } = useTranslation();

  const [helpFilling, setHelpFilling] = useState(false);
  const [loadFillingDelivery, setLoadFillingDelivery] = useState(true);

  const fetchContainerList = useCallback(
    async () => {
      getContainersList({
        page: null,
        limit: null,
        fieldSort: "filling_level",
        orderSort: "-1",
        typeFillingFilter: "all",
      }).then((response) => {
        if (response.status === 200) {
          setArrayFillingDelivery(response.data.arrayFillingDelivery);
          setFillingRateDelivery(response.data.arrayFillingRateDelivery);
          setCountContainers(response.data.countContainers);
          setLoadFillingDelivery(false);
        }
      });
    },
    // eslint-disable-next-line
    [],
  );

  useEffect(() => {
    fetchContainerList();
  }, [fetchContainerList]);

  return (
    <section className="dashboard-card">
      <article className="dashboard-card-top">
        <div className="dashboard-indicator">
          <article>
            <div className="flex flex-col ml-3 mt-1">
              <div className="flex flex-row">
                <h3
                  className="text-blush text-3xl font-semibold"
                  style={{ marginTop: -2 }}
                >
                  {countContainers}
                </h3>
                <h5 className="font-semibold text-lg mt-1.5 ml-2">
                  {t(`area/container${countContainers > 1 ? "_plural" : ""}`)}
                </h5>
              </div>
              <h5 className="font-semibold text-lg" style={{ marginTop: -13 }}>
                {t(`being monitored${countContainers > 1 ? "_plural" : ""}`)}
              </h5>
            </div>
          </article>
        </div>
        <i
          className="fas fa-info-circle text-gainsboro"
          style={{ marginTop: "10px", marginRight: "-5px" }}
          onMouseOver={() => setHelpFilling(true)}
          onMouseOut={() => setHelpFilling(false)}
        ></i>
      </article>

      {loadFillingDelivery ? (
        <SkeletonLoader />
      ) : (
        <>
          <article className="dashboard-card-content">
            <div style={{ maxHeight: "30px", width: "80%", marginTop: "30px" }}>
              <MultipleLineAndBarChart
                stackedChart={true}
                type="bar"
                borderWidthChart={0}
                labelsChart={[""]}
                aspectRatioChart={12}
                datasetsChart={datasetsFillingDelivery}
                indexAxisChart="y"
                maintainAspectRatioChart={false}
                displayGrid={false}
                displayTicks={false}
                xAxisType={"linear"}
                reverseChart={true}
                displayLegend={false}
                displayTooltip={false}
                messageLabelTooltip=""
                unitValueChart=""
                maxYAxis={null}
                height={null}
              />
            </div>
            <section>
              <section className="dashboard-filling">
                {arrayFillingDelivery.map((typeFilling, index) => {
                  return (
                    <article
                      className="dashboard-filling-delivery"
                      key={typeFilling._id}
                    >
                      <div
                        className="dashboard-filling-color"
                        style={{
                          backgroundColor: `${formattedFillingDeliveryColor(typeFilling.type)}`,
                        }}
                      ></div>
                      <span>{typeFilling.value}</span>
                      <Link
                        to={{
                          pathname: `/containers`,
                          state: {
                            typeFilling: typeFilling.type,
                          },
                        }}
                      >
                        <span className="dashboard-filling-type dashboard-text-underline">
                          {formattedFillingDelivery(
                            typeFilling.type,
                            "filling",
                            typeFilling.value,
                          )}
                        </span>
                      </Link>
                    </article>
                  );
                })}
              </section>
            </section>
          </article>

          <article className="dashboard-card-footer">
            {typeContainersDelivery.map((typeContainer, index) => {
              return (
                <div>
                  <span className="text-blush font-bold">
                    {((typeContainer.count * 100) / countContainers).toFixed(1)}
                    %
                  </span>{" "}
                  {formattedTypeContainer(typeContainer._id)}
                </div>
              );
            })}
          </article>

          {helpFilling ? (
            <article className="modal-item-key-help">
              <ul style={{ marginLeft: 10 }}>
                <li>
                  {t("Critical")} :{" "}
                  {t(
                    "Number of containers that have surpassed their critical limit",
                  )}
                  .
                </li>
                <li>
                  {t("In progress")} :{" "}
                  {t("Number of containers below the critical limit")}.
                </li>
                <li>
                  {t("Empty")} :{" "}
                  {t("Number of containers with a fill rate of 0%")}.
                </li>
              </ul>
            </article>
          ) : null}
        </>
      )}
    </section>
  );
};
