import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { checkIfMobile } from "../../components/mobileHelpers";

const LoginFooter = () => {
  const { i18n } = useTranslation();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(checkIfMobile());

    window.addEventListener("resize", checkIfMobile);

    return () => {
      window.removeEventListener("resize", checkIfMobile);
    };
  }, []);

  function toggleDropdown() {
    const dropdown = document.getElementById("language-dropdown");
    dropdown.classList.toggle("hidden");
  }

  function changeLanguage(lang) {
    i18n.changeLanguage(lang);
    toggleDropdown();
  }

  return (
    <div className="footer">
      <div className="footer-content">
        <div className="footer-item">
          <span className="copyright-logo">Akanthas © 2024</span>
        </div>
        {!isMobile && (
          <div className="footer-item">
            <span
              className="footer-link"
              onClick={() => {
                window.location.href = "/confidentialityagreement";
              }}
            >
              Confidentiality Agreement
            </span>
          </div>
        )}
        <div className="footer-item">
          <span className="language-select" onClick={toggleDropdown}>
            Language <span className="language-arrow">▼</span>
          </span>
          <div id="language-dropdown" className="language-dropdown hidden">
            <div
              className="language-option"
              onClick={() => changeLanguage("fr")}
            >
              Français
            </div>
            <div
              className="language-option"
              onClick={() => changeLanguage("en")}
            >
              English
            </div>
            <div
              className="language-option"
              onClick={() => changeLanguage("es")}
            >
              Español
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginFooter;
