import { getNotificationComments } from "../../services/notification";

export const getCommentsData = async (start, stop, keywordFilter) => {
  let commentsList = [];
  await getNotificationComments({
    start: start,
    stop: stop,
    keywordFilter: keywordFilter,
  }).then(async (response) => {
    if (Object.keys(response?.data).length > 0) {
      if (response?.data && response.data.length > 0) {
        await response.data.forEach(async (data) => {
          if (data?.comments?.length > 0) {
            data?.comments.forEach((item) => {
              commentsList.push({
                ...item,
                wasteName: data.container.wasteName,
                positioning: data.container.positioning,
              });
            });
          }
        });
      }
    }
  });
  return commentsList;
};
