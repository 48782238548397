import { useTranslation } from "react-i18next";

const UserHeader = ({ classColumnTable }) => {
  const { t } = useTranslation();
  return (
    <article className={classColumnTable}>
      <h6 className="column-title ml-2.5">{t("Name")}</h6>
      <h6 className="column-title">{t("First name")}</h6>
      <h6 className="column-title">{t("Related addresses")}</h6>
      <h6 className="column-title">{t("Role")}</h6>
      <h6 className="column-title column-mail">{t("Email")}</h6>
      <h6 className="column-title">{t("Phone")}</h6>
      <span className="column-title column-action"></span>
    </article>
  );
};

export default UserHeader;
