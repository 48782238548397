import fr from "../../static/assets/fr.png";
import en from "../../static/assets/en.png";
import es from "../../static/assets/es.png";

export const setStatusColor = (statusContainer) => {
  if (statusContainer === "drop_driver") {
    return "green-background";
  } else if (statusContainer === "remove_driver") {
    return "red-background";
  } else if (statusContainer === "collect") {
    return "orange-background";
  } else if (statusContainer === "maintenance") {
    return "grey-background";
  } else if (statusContainer === "store_logistic") {
    return "blue-background";
  } else if (statusContainer === "in_use_logistic") {
    return "pink-background";
  } else {
    return "grey-background";
  }
};

export const indexParentCategory = {
  "Papiers/Cartons": 0,
  Métaux: 1,
  Plastiques: 2,
  Verre: 3,
  Bois: 4,
  "Fraction Minérale": 5,
  Plâtre: 6,
  Biodéchets: 7,
  Textile: 8,
  Autres: 9,
};

export const getMostRecentHistory = (history) => {
  if (!history || history.length === 0) {
    return null;
  }
  let mostRecent = history[0];
  for (let i = 1; i < history.length; i++) {
    const currentTime = new Date(history[i]._time);
    const mostRecentTime = new Date(mostRecent._time);

    if (currentTime > mostRecentTime) {
      mostRecent = history[i];
    }
  }
  return mostRecent;
};

export const getLicensesForContainers = (containers, type) => {
  let containersPerLicense = 0;
  if (type === "monitoring") {
    containersPerLicense = 4;
  } else {
    containersPerLicense = 1;
  }
  const licenseCount = Math.ceil(containers / containersPerLicense);
  const userCount = licenseCount * 3;
  return { userCount: userCount, licenseCount: licenseCount };
};

export const getCameraId = (url) => {
  const startIndex = url.indexOf("camera-ak_");
  const endIndex = url.indexOf("/", startIndex);
  return url.substring(startIndex + "camera-ak_".length, endIndex);
};

export const languageData = [
  { label: "Français", value: "fr", flag: fr },
  { label: "Anglais", value: "en", flag: en },
  { label: "Espagnol", value: "es", flag: es },
];

export const checkStatusList = [
  {
    labelFr: "Vérifié",
    labelEn: "Done",
    value: "done",
    icon: "fas fa-check-circle text-[#7dd958]",
  },
  {
    labelFr: "Non Vérifié",
    labelEn: "Not done",
    value: "not done",
    icon: "fas fa-times-circle text-red",
  },
  {
    labelFr: "Interrogation",
    labelEn: "Needs action",
    value: "needs action",
    icon: "fas fa-question-circle text-gray-500",
  },
];

export const tagsOptions = [
  {
    labelFr: "Déclassement",
    labelEn: "Downgrading",
    value: "downgrading",
  },
  {
    labelFr: "Surcharge",
    labelEn: "Overload",
    value: "overload",
  },
  {
    labelFr: "Passage à vide",
    labelEn: "Empty run",
    value: "empty run",
  },
  {
    labelFr: "Facturée sans prestation réalisée",
    labelEn: "Billed without service rendered",
    value: "billed without service rendered",
  },
];
