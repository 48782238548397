import React from "react";
import { FaLock, FaUnlock } from "react-icons/fa";

const ContainerToggleLockButton = ({ isActive, onToggle, isVisible }) => {
  if (!isVisible) return null;

  return (
    <div
      style={{
        cursor: "pointer",
        fontSize: "1.2em",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "40px",
        height: "40px",
        borderRadius: "50%",
        backgroundColor: "var(--akanthas-yellow)",
        color: "black",
      }}
      onClick={onToggle}
    >
      {isActive ? <FaUnlock /> : <FaLock />}
    </div>
  );
};

export default ContainerToggleLockButton;
