export const isVerifyPassword = (
  googleSSORequired,
  password,
  passwordConfirm,
  setErrorForm,
  t,
) => {
  let isVerify = true;
  if (googleSSORequired !== true) {
    if (password !== undefined) {
      if (password.length < 6) {
        setErrorForm(t("Your password must be at least 6 characters long"));
        isVerify = false;
      } else if (password !== passwordConfirm) {
        setErrorForm(t("Your password must be similar"));
        isVerify = false;
      }
    }
  }

  return isVerify;
};
