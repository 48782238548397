import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import AppHeaderBar from "./AppHeaderBar";
import AppNavBar from "./AppNavBar";
import { FaExclamationTriangle } from "react-icons/fa";
import {
  formattedDate,
  formattedTime,
} from "../components/formatted/FormattedDateAndTime";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CustomSwitch } from "../components/CustomSwitch";

const NotificationRow = ({ notification }) => {
  const history = useHistory();
  const { i18n } = useTranslation();

  const styles = {
    row: {
      display: "flex",
      cursor: "pointer",
      padding: "10px",
      borderBottom: "1px solid #ddd",
    },
    icon: {
      marginRight: "10px",
    },
    content: {
      flex: 1,
    },
    date: {
      fontSize: "12px",
      color: "#888",
    },
  };

  const handleClick = async () => {
    history.push(`/app/notificationsdetails`, {
      notificationId: notification._id,
      title: notification.title,
      description: notification.description,
      date: notification.date,
      imageUrl: notification.imageUrl,
      xBox: notification.xBox,
      yBox: notification.yBox,
      wBox: notification.wBox,
      hBox: notification.hBox,
      detectedObjects: notification.detectedObjects,
      containerId: notification.containerId,
    });
  };

  return (
    <div
      className={`notification-row mb-2 ${notification.read ? "" : "border-[#00a7a6] drop-shadow-lg"}`}
      onClick={handleClick}
      style={styles.row}
    >
      <div className="notification-icon">
        <FaExclamationTriangle />
      </div>
      <div className="notification-content">
        <span className="notification-date">
          {" "}
          {formattedDate(notification.date, i18n.language)} -{" "}
          {formattedTime(notification.date)}
        </span>
        <div className="notification-details">
          <h3>{notification.description}</h3>
          <p>
            {notification.wasteName} - {notification.addressName}
          </p>
        </div>
      </div>
    </div>
  );
};

const AppNotifications = () => {
  const [switchValue, setSwitchValue] = useState(false);
  const notifications = [...useSelector((state) => state.notification.data)];

  const handleSwitchChange = (event) => {
    setSwitchValue(event.target.checked);
  };

  return (
    <div>
      <AppHeaderBar title="Notifications" />
      <div className="mobileapp-container">
        <div className="flex justify-between items-center w-full my-2">
          <span>Notifications non lues</span>
          <CustomSwitch
            className="flex justify-end"
            sx={{ m: 1 }}
            checked={switchValue}
            onChange={handleSwitchChange}
          />
        </div>
        <div className="notifications-list mb-16">
          {notifications.length > 0 &&
            notifications
              .filter((notification) =>
                switchValue ? !notification.read : true,
              )
              .reverse()
              .map((notification) => (
                <NotificationRow
                  key={notification.id}
                  notification={notification}
                />
              ))}
        </div>
      </div>
      <AppNavBar />
    </div>
  );
};

export default AppNotifications;
