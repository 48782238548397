import React, { useState } from "react";
import Cookies from "js-cookie";

const FloatingToggle = ({ viewMode, setViewMode }) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleModeChange = (newMode) => {
    setViewMode(newMode);
    Cookies.set("viewMode", newMode, { secure: true });
  };

  return (
    <div className="floating-toggle">
      <div
        style={{
          position: "absolute", // Position absolue pour l'alignement
          right: 0, // Fixé au bord droit
          top: 0, // Aligné verticalement
          height: "100%", // Prend toute la hauteur de l'encadré
          width: "30px", // Largeur constante
          backgroundColor: isOpen ? "rgba(0,0,0,0.1)" : "transparent", // Bande visible ou non
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
        }}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <div
          style={{
            fontSize: "18px",
            transform: isOpen ? "rotate(0deg)" : "rotate(-180deg)", // Rotation de la flèche
            transition: "transform 0.3s ease",
          }}
        >
          ➤
        </div>
      </div>

      {isOpen && (
        <div className="toggle-content">
          <strong>Administrateur Akanthas</strong>
          <p>Choisir mon mode d'affichage :</p>
          <div className="toggle-switch">
            <span style={{ fontSize: 13 }}>Mode client</span>
            <div
              className={`switch ${viewMode === "admin" ? "admin" : "client"}`}
              onClick={() =>
                handleModeChange(viewMode === "client" ? "admin" : "client")
              }
            >
              <div className="knob" />
            </div>
            <span style={{ fontSize: 13 }}>Mode admin</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default FloatingToggle;
