/**
 * Formatted date according to locale standards.
 */
export const formattedDate = (
  date,
  language = "fr",
  todayToString = false,
  todayToEmptyString = false,
) => {
  const today = new Date().toDateString();
  const newDate = new Date(date).toDateString();

  if (newDate === today && !todayToString) {
    return todayToEmptyString
      ? ""
      : language === "en"
        ? "Today"
        : language === "es"
          ? "Hoy"
          : "Aujourd'hui";
  }

  const formattedString = new Date(date)
    .toLocaleDateString(language, {
      month: "short",
      day: "numeric",
      year: "numeric",
    })
    .replace(/^\w/, (c) => c.toUpperCase());

  return language === "en"
    ? formattedString
    : language === "es"
      ? formattedString.replace("de", "")
      : formattedString;
};

/**
 * Formatted time according to french locale standard.
 * Exemple of the expected format:
 */
export const formattedTime = (scanDate) => {
  const options = { hour: "2-digit", minute: "2-digit" };
  return new Date(scanDate).toLocaleTimeString("fr-FR", options);
};

export const deltaMilliseconds = (dateFrom, dateTo) => {
  let from = new Date(dateFrom)?.getTime();
  let to = new Date(dateTo)?.getTime();
  return to - from;
};

export const elapsedTimeContainer = (
  dateMilliseconds,
  language,
  includeAgo,
  dateStopMs = new Date()?.getTime(),
) => {
  if (dateMilliseconds) {
    let currentDateMilliseconds = dateStopMs;
    let scanDateFormatted = new Date(dateMilliseconds)?.getTime();
    let deltaMilliseconds = currentDateMilliseconds - scanDateFormatted;

    let deltaSeconds = Math.floor(deltaMilliseconds / 1000);
    let deltaMinutes = Math.floor(deltaSeconds / 60);
    let deltaHours = Math.floor(deltaMinutes / 60);
    let deltaDays = Math.floor(deltaHours / 24);
    let deltaMonths = Math.floor(deltaDays / 30);

    let sinceDate = "";

    if (deltaMonths >= 1) {
      sinceDate =
        language === "en"
          ? includeAgo
            ? `${deltaMonths} ${deltaMonths === 1 ? "month" : "months"} ago`
            : `${deltaMonths} ${deltaMonths === 1 ? "month" : "months"}`
          : includeAgo
            ? `Il y a ${deltaMonths} mois`
            : `${deltaMonths} mois`;
    } else if (deltaDays >= 1) {
      sinceDate =
        language === "en"
          ? includeAgo
            ? `${deltaDays} ${deltaDays === 1 ? "day" : "days"} ago`
            : `${deltaDays} ${deltaDays === 1 ? "day" : "days"}`
          : includeAgo
            ? `Il y a ${deltaDays} ${deltaDays === 1 ? "jour" : "jours"}`
            : `${deltaDays} ${deltaDays === 1 ? "jour" : "jours"}`;
    } else if (deltaHours >= 1) {
      sinceDate =
        language === "en"
          ? includeAgo
            ? `${deltaHours} ${deltaHours === 1 ? "hour" : "hours"} ago`
            : `${deltaHours} ${deltaHours === 1 ? "hour" : "hours"}`
          : includeAgo
            ? `Il y a ${deltaHours} ${deltaHours === 1 ? "heure" : "heures"}`
            : `${deltaHours} ${deltaHours === 1 ? "heure" : "heures"}`;
    } else if (deltaMinutes >= 1) {
      sinceDate =
        language === "en"
          ? includeAgo
            ? `${deltaMinutes} ${deltaMinutes === 1 ? "minute" : "minutes"} ago`
            : `${deltaMinutes} ${deltaMinutes === 1 ? "minute" : "minutes"}`
          : includeAgo
            ? `Il y a ${deltaMinutes} ${deltaMinutes === 1 ? "minute" : "minutes"}`
            : `${deltaMinutes} ${deltaMinutes === 1 ? "minute" : "minutes"}`;
    } else if (deltaSeconds >= 1) {
      sinceDate =
        language === "en"
          ? includeAgo
            ? `${deltaSeconds} ${deltaSeconds === 1 ? "second" : "seconds"} ago`
            : `${deltaSeconds} ${deltaSeconds === 1 ? "second" : "seconds"}`
          : includeAgo
            ? `Il y a ${deltaSeconds} ${deltaSeconds === 1 ? "seconde" : "secondes"}`
            : `${deltaSeconds} ${deltaSeconds === 1 ? "seconde" : "secondes"}`;
    }

    return sinceDate;
  }
};

export const formattedDuration = (milliseconds, language) => {
  let deltaSeconds = Math.floor(milliseconds / 1000);
  let deltaMinutes = Math.floor(deltaSeconds / 60);
  let deltaHours = Math.floor(deltaMinutes / 60);
  let deltaDays = Math.floor(deltaHours / 24);

  if (deltaDays >= 1) {
    if (language === "en") {
      return `${deltaDays} day${deltaDays !== 1 ? "s" : ""}, ${deltaHours - deltaDays * 24} hour${deltaHours - deltaDays * 24 > 1 ? "s" : ""}`;
    } else {
      return `${deltaDays} jour${deltaDays !== 1 ? "s" : ""}, ${deltaHours - deltaDays * 24} heure${deltaHours - deltaDays * 24 > 1 ? "s" : ""}`;
    }
  } else {
    if (deltaHours >= 1) {
      if (language === "en") {
        return `${deltaHours} hour${deltaHours !== 1 ? "s" : ""}`;
      } else {
        return `${deltaHours} heure${deltaHours !== 1 ? "s" : ""}`;
      }
    } else {
      if (language === "en") {
        return `< 1 hour`;
      } else {
        return `< 1 heure`;
      }
    }
  }
};

export const sinceDate = (dateFrom, dateTo, language) => {
  let from = new Date(dateFrom)?.getTime();
  let to = new Date(dateTo)?.getTime();

  let deltaMilliseconds = to - from;
  let deltaSeconds = Math.floor(deltaMilliseconds / 1000);
  let deltaMinutes = Math.floor(deltaSeconds / 60);
  let deltaHours = Math.floor(deltaMinutes / 60);
  let deltaDays = Math.floor(deltaHours / 24);
  let deltaMonths = Math.floor(deltaDays / 30);
  let deltaYears = Math.floor(deltaMonths / 12);

  let sinceDate = "";

  if (deltaYears >= 1) {
    if (language === "en") {
      sinceDate = `${deltaYears} year${deltaYears !== 1 ? "s" : ""}, ${deltaMonths - deltaYears * 12} months`;
    } else {
      sinceDate = `${deltaYears} an${deltaYears !== 1 ? "s" : ""} et ${deltaMonths - deltaYears * 12} mois`;
    }
  } else if (deltaMonths >= 1) {
    if (language === "en") {
      sinceDate = `${deltaMonths} months${deltaDays - deltaMonths * 30 > 0 ? ", " + (deltaDays - deltaMonths * 30) + " day" + (deltaDays - deltaMonths * 30 > 1 ? "s" : "") : ""}`;
    } else {
      sinceDate = `${deltaMonths} mois${deltaDays - deltaMonths * 30 > 0 ? " et " + (deltaDays - deltaMonths * 30) + " jour" + (deltaDays - deltaMonths * 30 > 1 ? "s" : "") : ""}`;
    }
  } else if (deltaDays >= 1) {
    sinceDate = `${deltaDays} ${language === "en" ? "day" : "jour"}${deltaDays > 1 ? "s" : ""}`;
  } else if (deltaHours >= 1) {
    sinceDate = `${deltaHours} ${language === "en" ? "hour" : "heure"}${deltaHours > 1 ? "s" : ""}`;
  } else if (deltaMinutes >= 1) {
    sinceDate = `${deltaMinutes} minute${deltaMinutes > 1 ? "s" : ""}`;
  } else if (deltaSeconds >= 1) {
    sinceDate = `${deltaSeconds} ${language === "en" ? "second" : "seconde"}${deltaSeconds > 1 ? "s" : ""}`;
  } else {
    sinceDate = "-";
  }

  return sinceDate;
};

export const dateToName = (dateString) => {
  // Format date like 2023_08_10_13T42
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  return `${year}_${month}_${day}_${hours}T${minutes}`;
};

export const formattedDateDocument = (date) => {
  const jour = String(new Date(date).getDate()).padStart(2, "0");
  const mois = String(new Date(date).getMonth() + 1).padStart(2, "0");
  const annee = new Date(date).getFullYear();

  return `${jour}/${mois}/${annee}`;
};

export const formattedDayName = (date, language) => {
  return new Date(date).toLocaleDateString(
    language === "en" ? "en-EN" : "fr-FR",
    { weekday: "short" },
  );
};

export const formattedLongHandFr = (date, language) => {
  const hours = String(date.getHours()).padStart(2, "0");
  const jour = String(new Date(date).getDate()).padStart(2, "0");
  const mois = new Date(date).toLocaleDateString(
    language === "fr" ? "fr-FR" : "en-EN",
    { month: "long" },
  );
  const annee = new Date(date).getFullYear();
  const minutes = date.getMinutes();
  const formattedMinutes = (minutes < 10 ? "0" : "") + minutes;

  return `${jour} ${mois} ${annee} ${language === "fr" ? "à" : "at"} ${hours}:${formattedMinutes}`;
};

export const formatDateToString = (date) => {
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = String(date.getFullYear()).substring(2);
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${day}_${month}_${year}_${hours}h${minutes}min`;
};

export const isMoreThanFiveMinutesAgo = (dateString) => {
  const dateGiven = new Date(dateString);
  const currentDate = new Date();
  const differenceMinutes = (currentDate - dateGiven) / (1000 * 60);
  return differenceMinutes > 5;
};

export const getStartOfMonth = (date) => {
  return new Date(date.getFullYear(), date.getMonth(), 1);
};

export const getStartOfWeek = (date) => {
  const day = date.getDay();
  const diff = (day === 0 ? -6 : 1) - day;
  return new Date(date.getFullYear(), date.getMonth(), date.getDate() + diff);
};

export const getEndOfMonth = (date) => {
  const endOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  endOfMonth.setHours(23, 59, 59, 999);
  return endOfMonth;
};

export const getMonthsAgo = (date, monthsAgo, endOfMonth) => {
  let newDate = new Date(date);
  newDate.setMonth(newDate.getMonth() - (monthsAgo - 1));
  newDate.setDate(1);
  if (endOfMonth) {
    newDate = getEndOfMonth(newDate);
  } else {
    newDate.setHours(0, 0, 0, 0);
  }
  return newDate;
};

export const getPreviousPeriod = (date, monthsAgo) => ({
  start: getStartOfMonth(
    new Date(date.getFullYear(), date.getMonth() - monthsAgo),
  ),
  end: getEndOfMonth(new Date(date.getFullYear(), date.getMonth() - monthsAgo)),
});

export const frenchMonth = [
  "Janvier",
  "Février",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juillet",
  "Août",
  "Septembre",
  "Octobre",
  "Novembre",
  "Décembre",
];

export const englishMonth = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const getMonthsInRange = (start, stop, language) => {
  let months = "";
  const startMonth = new Date(start.getFullYear(), start.getMonth(), 1);
  const endMonth = new Date(stop.getFullYear(), stop.getMonth(), 1);

  const monthDiff =
    (endMonth.getFullYear() - startMonth.getFullYear()) * 12 +
    (endMonth.getMonth() - startMonth.getMonth());

  if (monthDiff > 6) {
    let startMonthName =
      language === "fr"
        ? frenchMonth[startMonth.getMonth()]
        : englishMonth[startMonth.getMonth()];

    let endMonthName =
      language === "fr"
        ? frenchMonth[endMonth.getMonth()]
        : englishMonth[endMonth.getMonth()];
    if (start.getFullYear() !== stop.getFullYear()) {
      startMonthName = startMonthName + ` ${start.getFullYear()}`;
      endMonthName = endMonthName + ` ${stop.getFullYear()}`;
    }
    if (language === "fr") {
      months = `De ${startMonthName} à ${endMonthName}`;
    } else {
      months = `${startMonthName} to ${endMonthName}`;
    }
    return months;
  } else {
    const current = new Date(startMonth);

    while (current <= endMonth) {
      const monthName =
        language === "fr"
          ? frenchMonth[current.getMonth()]
          : englishMonth[current.getMonth()];
      months = months === "" ? monthName : `${months}, ${monthName}`;
      current.setMonth(current.getMonth() + 1);
    }

    return months;
  }
};
