import CalendarComponent from "../../components/CalendarComponent";
import React, { useEffect, useState } from "react";
import {
  capitalizeFirstLetter,
  currentMonthName,
  previousMonthName,
} from "../../components/utils/Date";
import Multiselect from "multiselect-react-dropdown";
import { Button, Input } from "reactstrap";
import { useTranslation } from "react-i18next";

const StatisticsFilter = ({
  labeledkeywordFilterOptions,
  multiselectKeywordRef,
  keywordFilter,
  setKeywordFilter,
  onChangeCalendar,
  displayCalendar,
  setDisplayCalendar,
  periodHistoryStart,
  periodHistoryStop,
  selectPeriodFilter,
  selectedPeriod,
  showDate,
  setShowDate,
  periodValue,
  setPeriodValue,
  keyword,
  setKeyword,
}) => {
  const { t, i18n } = useTranslation();
  const [filterOptions, setFilterOptions] = useState([]);

  useEffect(
    () => {
      setFilterOptions(labeledkeywordFilterOptions);
      setPeriodValue(selectedPeriod);
      setKeyword(keywordFilter);
    },
    // eslint-disable-next-line
    [],
  );

  return (
    <div
      className={`absolute w-full z-10 p-5 mt-2 bg-[#f8f9fa] border-2 border-[#D0D0D0] rounded-xl transition duration-200`}
      style={{ top: "100%", left: 0 }}
    >
      <div className="flex flex-row gap-2 w-full mb-2">
        {/* Multiselect */}
        <div className="flex flex-col w-3/6">
          <span className="font-semibold">{t("Container")}</span>
          <Multiselect
            options={filterOptions}
            groupBy="cat"
            displayValue="name"
            onSelect={(value) => setKeyword({ value })}
            onRemove={(value) => setKeyword({ value })}
            hidePlaceholder={true}
            emptyRecordMsg={t("No available option")}
            placeholder={t("Search")}
            closeIcon="circle"
            selectedValues={keyword.value}
          />
          {keywordFilter.length > 0 ? (
            <i
              className="fas fa-trash absolute top-3 right-2"
              onClick={() => {
                multiselectKeywordRef.current.resetSelectedValues();
                setKeywordFilter([]);
                setKeyword([]);
              }}
            ></i>
          ) : null}
        </div>
        {/* Calendar */}
        {showDate && (
          <div className="w-1/3 mt-4">
            <CalendarComponent
              classNameBase="flex flex-row "
              onChangeCalendar={onChangeCalendar}
              displayCalendar={displayCalendar}
              setDisplayCalendar={setDisplayCalendar}
              periodHistoryStart={periodHistoryStart}
              periodHistoryStop={periodHistoryStop}
            />
          </div>
        )}
        <div className="flex flex-col w-3/6 ml-2">
          <span className="font-semibold">{t("Period")}</span>
          <Input
            type="select"
            style={{ borderWidth: "0px" }}
            name="select-graphe"
            onChange={(event) => {
              setPeriodValue(event.target.value);
              if (event.target.value === "calendar") {
                setShowDate(true);
              } else {
                setShowDate(false);
              }
            }}
            value={periodValue}
          >
            <option value="0">
              {t("Current month")} :{" "}
              {capitalizeFirstLetter(currentMonthName(i18n.language))}
            </option>
            <option value="1">
              {t("Last month")} :{" "}
              {capitalizeFirstLetter(previousMonthName(i18n.language))}
            </option>
            <option value="-3mo">{t("Last 3 months")}</option>
            <option value="-6mo">{t("Last 6 months")}</option>
            <option value="-12mo">{t("Last 12 months")}</option>
            <option value="calendar">{t("Custom period")}</option>
          </Input>
        </div>
      </div>
      <div className="flex justify-center w-full">
        <Button
          className="bg-[#00A7A6] w-40 mt-4"
          onClick={() => {
            selectPeriodFilter(periodValue);
          }}
        >
          {t("Validate")}
        </Button>
      </div>
    </div>
  );
};

export default StatisticsFilter;
