import axios from "axios";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Logo from "../../static/assets/logo_rvg.png";
import LoginFooter from "./LoginFooter.js";
import { useTranslation } from "react-i18next";

const ForgotPassword = ({ API }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    const themeColor = document.querySelector('meta[name="theme-color"]');
    themeColor.setAttribute("content", "#f5f5f5"); // Couleur spécifique pour la page login
    // Nettoyage : remettre la couleur par défaut quand on quitte la page
    return () => {
      themeColor.setAttribute("content", "#00A7A6"); // Couleur par défaut quand on quitte
    };
  }, []); // Le tableau vide garantit que le code s'exécute uniquement au montage et démontage de la page

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (email) {
        await axios
          .post(`${API}password/forgot`, {
            email: email,
          })
          .then((response) => {
            if (response.status === 200) {
              setMessage(response.data.message);
            }
          });
      }
    } catch (error) {
      setMessage(error.response.data.message);
    }
  };

  return (
    <div className="login-page">
      <div className="header">
        <img src={Logo} alt="Logo de la boîte" className="logo" />
      </div>

      <div className="login-container">
        <div className="login-box">
          <h1 className="sign-in-message">{t("Forgot Password")}</h1>

          <p className="tagline">
            {t(
              "Lost your way? No worries! We'll help you reset your password.",
            )}
          </p>

          <form onSubmit={handleSubmit} className="login-form">
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
                setMessage("");
              }}
              required
            />
            {message === "message_sent" && (
              <p className="success-message">
                {t("Message sent successfully, check your mail!")}
              </p>
            )}
            {message === "invalid_email" && (
              <p className="error-message">{t("Invalid email address")}</p>
            )}
            {message !== "message_sent" && (
              <button
                type="submit"
                className="login-button"
                style={{ marginTop: "10px" }}
              >
                {t("Reset Password")}
              </button>
            )}
          </form>

          <button
            onClick={() => history.push("/login")}
            className="back-link"
            style={{ marginTop: "20px" }}
          >
            {t("Back to sign in")}
          </button>
        </div>
      </div>

      <LoginFooter />
    </div>
  );
};

export default ForgotPassword;
