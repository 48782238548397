import { useTranslation } from "react-i18next";

const CameraHeader = ({ classColumnTable }) => {
  const { t } = useTranslation();
  return (
    <article className={classColumnTable}>
      <h6 className="column-title" style={{ marginLeft: "15px" }}>
        {t("ID")}
      </h6>
      <h6 className="column-title">{t("Last image")}</h6>
      <h6 className="column-title">{t("Address")}</h6>
      <h6 className="column-title">{t("State")}</h6>
      <h6 className="column-title">{t("Solar panel ID")}</h6>
      <h6 className="column-title">{t("Battery")}</h6>
      <span className="column-title column-action"></span>
    </article>
  );
};

export default CameraHeader;
