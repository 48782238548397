import axiosInstance from "./axiosInstance";

export const getNotifications = (params) => {
  return axiosInstance.get(`notifications/getNotifications`, {
    params: params,
  });
};

export const updateNotificationStatus = (id) => {
  return axiosInstance.post(`notification/updateStatus/${id}`);
};

export const getNotificationComments = (params) => {
  return axiosInstance.get(`/notification/comments`, {
    params: params,
  });
};

export const addCampaign = (params) => {
  return axiosInstance.post(`campaign/add`, params);
};

export const getCampaigns = (params) => {
  return axiosInstance.get(`campaign/getCampaign`, {
    params: params,
  });
};

export const updateCampaign = (id, params) => {
  return axiosInstance.post(`campaign/update/${id}`, params);
};

export const deleteCampaign = (id) => {
  return axiosInstance.post(`campaign/delete/${id}`);
};
