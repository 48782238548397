import React, { useEffect, useState } from "react";
import { Input, Label, Modal, ModalBody, ModalHeader } from "reactstrap";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { formattedDate, formattedTime } from "./formatted/FormattedDateAndTime";
import { LoadingScreen } from "./loader/LoadingScreen";

const ContainerModalComment = ({
  data,
  displayComment,
  setDisplayComment,
  superContainer,
  saveWeight,
  comments,
  comment,
  setComment,
  loading,
}) => {
  const { t, i18n } = useTranslation();
  const { token, ...user } = JSON.parse(localStorage.getItem("user"));
  const [openCommentId, setOpenCommentId] = useState(null);
  const [pendingAction, setPendingAction] = useState(null);

  useEffect(
    () => {
      setComment({
        time: new Date().toISOString(),
        author: user,
        text: "",
      });
    },
    // eslint-disable-next-line
    [displayComment],
  );

  useEffect(() => {
    if (comment && pendingAction === "delete") {
      saveWeight(
        superContainer && superContainer._id !== undefined
          ? superContainer._id
          : data._id,
      );
      setPendingAction(null);
    }
  }, [comment, pendingAction, superContainer, data, saveWeight]);

  const handleComment = (value) => {
    setComment((prevState) => ({
      ...prevState,
      text: value,
    }));
  };

  const toggleCommentMenu = (id) => {
    if (openCommentId === id) {
      setOpenCommentId(null);
    } else {
      setOpenCommentId(id);
    }
  };

  const handleAction = (commentId, action) => {
    const selectedComment = comments.find((_, index) => index === commentId);
    if (!selectedComment) return;
    const updatedComment =
      action === "delete"
        ? { ...selectedComment, toDelete: true }
        : selectedComment;
    setComment(updatedComment);
    if (action === "delete") {
      setPendingAction("delete");
    }
  };

  return (
    <Modal className="w-2/3" isOpen={displayComment}>
      <ModalHeader>
        <span className="text-blue">{t("Comments")}</span>
        <CloseIcon
          style={{
            color: "var(--gray)",
            cursor: "pointer",
          }}
          onClick={() => {
            setDisplayComment(false);
            setOpenCommentId(null);
          }}
        />
      </ModalHeader>
      <ModalBody className="bg-[#e5e7eb]">
        <div className="flex flex-col gap-3 w-full">
          <div className="flex flex-col w-full">
            <Label for="exampleText">{t("Your comment")}</Label>
            <Input
              className="h-24"
              id="exampleText"
              name="text"
              type="textarea"
              value={comment.text}
              onChange={(event) => handleComment(event.target.value)}
            />
            <div className="flex flex-end w-full mt-2">
              {comment.text !== "" && (
                <button
                  class="p-2 mr-2 border border-gray-300 bg-neutral-100 rounded-lg hover:bg-neutral-300"
                  onClick={() =>
                    setComment({
                      time: new Date().toISOString(),
                      author: user,
                      text: "",
                    })
                  }
                >
                  Annuler
                </button>
              )}
              <button
                type="button"
                class="p-2 w-28 text-md font-medium text-white rounded-lg"
                style={{ backgroundColor: "#00A7A6" }}
                onClick={() =>
                  saveWeight(
                    superContainer && superContainer._id !== undefined
                      ? superContainer._id
                      : data._id,
                  )
                }
              >
                {t("Validate")}
              </button>
            </div>
          </div>
          <div className="flex flex-col w-full max-h-96 gap-2 overflow-y-auto">
            {comments.length > 0 &&
              comments.reverse().map((item, index) => (
                <div className="flex flex-row gap-2">
                  <div className="flex items-center justify-center text-center rounded-full bg-[#00A7A6] h-8 w-8">
                    <i className="fas fa-user text-white"></i>
                  </div>
                  <div
                    className={`flex flex-col bg-white rounded-md px-3 py-2 w-full ${comment.time === item.time ? "border-2 border-[#00A7A6]" : ""}`}
                  >
                    <div className="flex flex-row w-full">
                      <div className="flex gap-2 w-1/3">
                        <span className="text-[#00A6A7]">
                          {item?.author.firstName} {item?.author.lastName}
                        </span>
                        <span className="text-gray-500">
                          {formattedDate(item.time, i18n.language)} -{" "}
                          {formattedTime(item.time)}
                        </span>
                      </div>
                      <div className="relative flex flex-end w-2/3">
                        {openCommentId === index ? (
                          <i
                            className="fas fa-times text-[#00A7A6] cursor-pointer"
                            onClick={() => toggleCommentMenu(index)}
                          ></i>
                        ) : (
                          <i
                            className="fas fa-ellipsis-v text-[#00A7A6] cursor-pointer"
                            onClick={() => toggleCommentMenu(index)}
                          ></i>
                        )}
                        {openCommentId === index && (
                          <div className="absolute flex flex-col w-28 bg-[#00A7A6] text-white rounded-md right-4">
                            <span
                              className="hover:bg-[#0B9C98] cursor-pointer px-2 rounded-md"
                              onClick={() => {
                                handleAction(index, "edit");
                                setOpenCommentId(null);
                              }}
                            >
                              {t("Edit")}
                            </span>
                            <span
                              className="hover:bg-[#0B9C98] cursor-pointer px-2 rounded-md"
                              onClick={() => {
                                handleAction(index, "delete");
                                setOpenCommentId(null);
                              }}
                            >
                              {t("Delete")}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                    <p className="text-gray-500 text-sm mt-2">{item.text}</p>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </ModalBody>
      {loading && <LoadingScreen />}
    </Modal>
  );
};

export default ContainerModalComment;
