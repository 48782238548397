import { FormGroup, Input, Label } from "reactstrap";
import { handleFormChange } from "../../../components/modals/EditableTable";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export const ContainerGeneralInformation = ({
  formData,
  setFormData,
  setErrorForm,
}) => {
  const { t } = useTranslation();
  const [volumeIsDisabled, setVolumeIsDisabled] = useState(false);
  const [dimensionsIsDisabled, setDimensionsIsDisabled] = useState(false);

  const activeVolumeField = (event) => {
    const newFormData = { ...formData };

    if (event.target.value !== "") {
      newFormData.dimensions.length = "";
      newFormData.dimensions.width = "";
      newFormData.dimensions.height = "";
      newFormData.volume = event.target.value;

      setErrorForm("");
      setDimensionsIsDisabled(true);
      setVolumeIsDisabled(false);
    } else {
      newFormData.volume = 0;
      setDimensionsIsDisabled(false);
      setVolumeIsDisabled(false);
      setErrorForm(
        `Veuillez renseigner le volume <strong>OU</strong> les dimensions de votre contenant`,
      );
    }
    setFormData(newFormData);
  };

  const activeDimensionsField = (event) => {
    const newFormData = { ...formData };

    const length = formData.dimensions.length;
    const width = formData.dimensions.width;
    const height = formData.dimensions.height;
    newFormData.volume = length * width * height;
    setFormData(newFormData);

    if (event.target.value !== "") {
      setVolumeIsDisabled(true);
      setDimensionsIsDisabled(false);
    } else {
      setErrorForm("");
      setDimensionsIsDisabled(false);
      setVolumeIsDisabled(false);
    }

    if (newFormData.volume === 0) {
      setErrorForm(
        `Veuillez renseigner le volume <strong>OU</strong> les dimensions de votre contenant`,
      );
    } else {
      setErrorForm("");
    }
  };

  return (
    <section className="flex flex-col w-1/4 min-h-full">
      {/* Volume */}
      <FormGroup className="flex flex-col w-full mb-3">
        <Label>
          Volume (m<sup>3</sup>)
        </Label>
        <Input
          className={`${volumeIsDisabled ? "bg-gainsboro-transparent" : "bg-white"} mx-1  border border-gray-300 text-gray-600 rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2 `}
          type="number"
          placeholder={`${t("Numbers only")} : 30`}
          id="volume"
          value={formData.volume > 0 ? formData.volume : ""}
          onChange={(event) => {
            activeVolumeField(event);
          }}
          name="volume"
          step={0.01}
          disabled={volumeIsDisabled}
        />
      </FormGroup>

      {/* Longeur */}
      <FormGroup className="flex flex-col w-full mb-3">
        <Label>{t("Length")} (m)</Label>
        <Input
          className={`${dimensionsIsDisabled ? "bg-gainsboro-transparent" : "bg-white"} mx-1 border border-gray-300 text-gray-600 rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2 `}
          type="number"
          id="length"
          placeholder={`${t("Numbers only")} : 5.8`}
          value={
            formData.dimensions.length > 0 ? formData.dimensions.length : ""
          }
          name="length"
          dictcategory="dimensions"
          onChange={(event) => {
            handleFormChange(event, formData, setFormData);
            activeDimensionsField(event);
          }}
          step={0.01}
          disabled={dimensionsIsDisabled}
        />
      </FormGroup>

      {/* Largeur */}
      <FormGroup className="flex flex-col w-full mb-3">
        <Label>{t("Width")} (m)</Label>
        <Input
          className={`${dimensionsIsDisabled ? "bg-gainsboro-transparent" : "bg-white"} mx-1 border border-gray-300 text-gray-600 rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2 `}
          type="number"
          id="width"
          placeholder={`${t("Numbers only")} : 5.8`}
          value={formData.dimensions.width > 0 ? formData.dimensions.width : ""}
          name="width"
          dictcategory="dimensions"
          onChange={(event) => {
            handleFormChange(event, formData, setFormData);
            activeDimensionsField(event);
          }}
          step={0.01}
          disabled={dimensionsIsDisabled}
        />
      </FormGroup>

      {/* Hauteur */}
      <FormGroup className="flex flex-col w-full mb-3">
        <Label>{t("Height")} (m)</Label>
        <Input
          className={`${dimensionsIsDisabled ? "bg-gainsboro-transparent" : "bg-white"} mx-1 border border-gray-300 text-gray-600 rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2 `}
          type="number"
          placeholder={`${t("Numbers only")} : 5.8`}
          value={
            formData.dimensions.height > 0 ? formData.dimensions.height : ""
          }
          name="height"
          dictcategory="dimensions"
          onChange={(event) => {
            handleFormChange(event, formData, setFormData);
            activeDimensionsField(event);
          }}
          step={0.01}
          disabled={dimensionsIsDisabled}
        />
      </FormGroup>
    </section>
  );
};
