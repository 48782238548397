import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SkeletonLoader from "../../components/utils/SkeletonLoader";
import { VolumePieChart } from "../../components/charts/VolumePieChart";

export const DashboardGaugeOnSiteVolume = ({
  isLoading,
  totalVolume,
  totalVolumeUsed,
  wasteCategory,
  wasteDelivery,
}) => {
  const { t } = useTranslation();
  const [help, setHelp] = useState(false);

  const labelsChartWaste = wasteDelivery.map((waste) => waste.wasteName);
  const dataChartWaste = wasteDelivery.map((waste) => waste.volume);

  return (
    <section className="dashboard-card">
      <article className="dashboard-card-top">
        <div className="dashboard-indicator">
          <article>
            <i
              className="fas fa-layer-group "
              style={{
                fontSize: "55px",
                color: "var(--akanthas-cyan)",
                marginLeft: -10,
                marginTop: 8,
              }}
            ></i>
            <div className="flex flex-col ml-3 mt-1">
              <div className="flex flex-row">
                <h3
                  className="text-blush text-3xl font-semibold"
                  style={{ marginTop: -2 }}
                >
                  {Math.round(totalVolumeUsed)} m<sup>3</sup>
                </h3>
                <h5
                  className="font-semibold text-lg ml-2"
                  style={{ marginTop: 7.5 }}
                >
                  {t("of waste")}
                </h5>
              </div>
              <h5
                className="font-semibold text-blue text-lg"
                style={{ marginTop: -13 }}
              >
                {t("currently on site")}
              </h5>
            </div>
          </article>
        </div>
        <i
          className="fas fa-info-circle text-gainsboro"
          style={{ marginTop: "10px", marginRight: "-5px" }}
          onMouseOver={() => setHelp(true)}
          onMouseOut={() => setHelp(false)}
        ></i>
      </article>
      {isLoading ? (
        <SkeletonLoader />
      ) : (
        <>
          <article className="dashboard-card-content mt-2">
            <section>
              <div style={{ maxHeight: "90%", marginRight: 5 }}>
                {dataChartWaste.length > 0 &&
                labelsChartWaste.length > 0 &&
                wasteCategory.length > 0 ? (
                  <VolumePieChart
                    dataChart={dataChartWaste}
                    labelsChartWaste={labelsChartWaste}
                    totalVolumeUsed={totalVolumeUsed}
                    wasteCategory={wasteCategory}
                  />
                ) : null}
              </div>
              <section className="flex flex-col w-1/2">
                <div className="w-1/2 m-auto">
                  <h6>
                    <span className="text-blush font-bold">
                      {Math.round((totalVolumeUsed * 100) / totalVolume)}%
                    </span>{" "}
                    {t("container usage")}
                  </h6>
                </div>
              </section>
            </section>
          </article>
          <article className="dashboard-card-footer">
            <Link
              to={{
                pathname: "/containers",
              }}
            >
              <div className="dashboard-text-underline">
                {t("View details")}
              </div>
            </Link>
          </article>
          {help ? (
            <article className="modal-item-key-help">
              {t(
                "Total volume of waste produced and present on site currently",
              )}
              .
            </article>
          ) : null}
        </>
      )}
    </section>
  );
};
