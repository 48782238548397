import React from "react";
import AppHeaderBar from "./AppHeaderBar";
import AppNavBar from "./AppNavBar";

const AppHome = () => {
  const storedUser = localStorage.getItem("user");
  const { token, ...user } = storedUser ? JSON.parse(storedUser) : {};

  return (
    <div>
      <AppHeaderBar title="Akanthas" />
      <div className="mobileapp-container">
        <div className="flex flex-col mt-44 text-center text-gray-600">
          <span className="text-xl">Bienvenue</span>
          <span className="text-xl">
            {user?.firstName} {user?.lastName}
          </span>
        </div>
      </div>
      <AppNavBar />
    </div>
  );
};

export default AppHome;
