import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { currentMonthName } from "../../components/utils/Date";
import { useTranslation } from "react-i18next";
import SkeletonLoader from "../../components/utils/SkeletonLoader";
import CharacterisationSvg from "../../components/utils/CharacterisationSvg";
import {
  getListRotationPeriods,
  totalVolumeCharacterization,
} from "../../services/container";

export const DashboardGaugeCharacterization = ({ characterization }) => {
  const { t, i18n } = useTranslation();
  const [countCharacterization, setCountCharacterization] = useState(0);
  const [characterizedVolume, setCharacterizedVolume] = useState(0);
  const [loadCharacterizations, setLoadCharacterizations] = useState(true);

  function totalCharacterizations(containersPeriods) {
    let sum = 0;
    for (const containerPeriod of containersPeriods) {
      sum = sum + containerPeriod.length;
    }
    return sum;
  }

  const fetchCharacterization = useCallback(
    async () => {
      await totalVolumeCharacterization({
        periodStart: 0,
      }).then((response) => {
        if (response.status === 200) {
          setCharacterizedVolume(response.data.totalVolumeCharacterization);
        }
      });
      await getListRotationPeriods(
        {
          periodMaterialStart: 0,
        },
        null,
      ).then((response) => {
        if (response.status === 200) {
          setCountCharacterization(
            totalCharacterizations(response.data.containersPeriods),
          );
        }
      });
      setLoadCharacterizations(false);
    },
    // eslint-disable-next-line
    [],
  );

  useEffect(() => {
    if (characterization) {
      fetchCharacterization();
    }
  }, [characterization, fetchCharacterization]);

  return (
    <section className="dashboard-card">
      <article className="dashboard-card-top">
        <div className="dashboard-indicator">
          <article>
            <div style={{ marginTop: 5, marginLeft: -10 }}>
              <CharacterisationSvg
                width={53}
                height={63}
                widthViewBox={22}
                heightViewBox={28}
              />
            </div>
            <div className="flex flex-col ml-3 mt-1">
              <div className="flex flex-row">
                <h3
                  className="text-blush text-3xl font-semibold"
                  style={{ marginTop: -2 }}
                >
                  {countCharacterization}
                </h3>
                <h5 className="font-semibold text-lg mt-1.5 ml-2">
                  {t("Characterization").toLowerCase()}
                  {countCharacterization > 1 && "s"}
                </h5>
              </div>
              <h5 className="font-semibold text-lg" style={{ marginTop: -13 }}>
                {t("during")}{" "}
                <span className="text-blush">
                  {currentMonthName(i18n.language)}
                </span>
              </h5>
            </div>
          </article>
        </div>
      </article>
      {loadCharacterizations && characterization ? (
        <SkeletonLoader />
      ) : (
        <>
          <div>
            {!characterization ? (
              <h4 className="text-cyan font-semibold">
                <span className="text-5xl">0</span>
                <span className="text-3xl"> {t("Container")}</span>
              </h4>
            ) : (
              <h4 className="text-cyan font-semibold text-5xl">
                {Math.round(characterizedVolume)} m<sup>3</sup>
              </h4>
            )}
            {characterization && (
              <span className="text-grey">{t("of waste analyzed")}</span>
            )}
          </div>

          <div className="dashboard-card-footer">
            <Link
              to={{
                pathname: "/containers",
              }}
            >
              <div className="dashboard-text-underline">
                {t("View details")}
              </div>
            </Link>
          </div>
        </>
      )}
    </section>
  );
};
