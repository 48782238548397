import { useTranslation } from "react-i18next";

const AddressHeader = ({ classColumnTable }) => {
  const { t } = useTranslation();

  return (
    <article className={classColumnTable}>
      <h6 className="column-title ml-2.5">{t("Name")}</h6>
      <h6 className="column-title">{t("Role")}</h6>
      <h6 className="column-title column-address">{t("Address")}</h6>
      <h6 className="column-title">Latitude</h6>
      <h6 className="column-title">Longitude</h6>
    </article>
  );
};

export default AddressHeader;
