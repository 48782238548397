import { dateToName } from "../../components/formatted/FormattedDateAndTime";

const accentMap = {
  á: "a",
  à: "a",
  â: "a",
  ä: "a",
  ã: "a",
  å: "a",
  æ: "ae",
  é: "e",
  è: "e",
  ê: "e",
  ë: "e",
  í: "i",
  ì: "i",
  î: "i",
  ï: "i",
  ó: "o",
  ò: "o",
  ô: "o",
  ö: "o",
  õ: "o",
  ø: "o",
  ú: "u",
  ù: "u",
  û: "u",
  ü: "u",
  ý: "y",
  ÿ: "y",
  ç: "c",
  ñ: "n",
  ß: "ss",
  œ: "oe",
};

export const formatFilename = (date, containerId, type) => {
  const newFilename = `${dateToName(date)}_CONT_${containerId}`;
  return newFilename;
};

export const removeAccents = (str) => {
  // eslint-disable-next-line
  return str.replace(/[^\u0020-\u007E]/g, (a) => accentMap[a] || a);
};
