import {
  Chart as ChartJS,
  ArcElement,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  TimeScale,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import "chartjs-adapter-date-fns";
import { fr, enUS } from "date-fns/locale";
import {
  formattedDate,
  formattedTime,
} from "../../components/formatted/FormattedDateAndTime";
import { useTranslation } from "react-i18next";

ChartJS.register(
  ArcElement,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  TimeScale,
  Filler,
);
const LineAndBarChart = ({
  dataChart,
  labelsChart,
  type,
  xAxisType,
  unitValueChart,
  maxYAxis,
  messageLabelTooltip,
  stepYAxis,
  xUnit,
}) => {
  const { i18n } = useTranslation();

  return (
    <Line
      type={type}
      data={{
        labels: labelsChart,
        datasets: [
          {
            label: "Cycle",
            type: type,
            data: dataChart,
            tension: 0.1,
            backgroundColor: "rgba(75,192,192,0.6)",
            borderColor: "rgba(75,192,192,1)",
            borderWidth: 2,
            fill: true,
          },
        ],
      }}
      options={{
        maintainAspectRatio: false,
        scales: {
          x: {
            offsetAfterAutoskip: true,
            type: xAxisType,
            time: {
              unit: xUnit || undefined,
              isoWeekday: true,
              displayFormats: {
                day: "d MMM",
                week: "w",
                hour: "HH:mm",
              },
            },
            adapters: {
              date: {
                locale: i18n.language === "en" ? enUS : fr,
              },
            },
            ticks: {
              display: true,
              autoSkip: true,
              maxTicksLimit: 10,
              maxRotation: 0,
              source: type === "bar" ? "data" : "auto",
              major: {
                enabled: true,
              },
              font: (context) => {
                const boldedTiks =
                  context.tick && context.tick.major ? "bold" : "";
                return {
                  weight: boldedTiks,
                };
              },
            },
          },
          y: {
            beginAtZero: true,
            max: maxYAxis,
            min: 0,
            ticks: {
              display: true,
              stepSize: stepYAxis,
              callback: function (value, index, values) {
                return value + " " + unitValueChart;
              },
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: true,
            backgroundColor: "#f8AA00",
            titleAlign: "center",
            callbacks: {
              title: function (context) {
                let label =
                  context[0].dataset.data[context[0].dataIndex].x || "";
                let labelCat = context[0].label;
                return xAxisType === "time"
                  ? `${formattedDate(label, i18n.language)}`
                  : labelCat;
              },
              label: function (context) {
                let value = context.dataset.data[context.dataIndex].y || "";
                let label = context.dataset.data[context.dataIndex].x || "";
                let messageTime = `${messageLabelTooltip}: ${value}${unitValueChart} ${type === "line" ? `à ${formattedTime(label)}` : ""}`;
                let messageCat = `${messageLabelTooltip}: ${context.formattedValue}`;
                return xAxisType === "time" ? messageTime : messageCat;
              },
            },
          },
        },
      }}
    />
  );
};

export default LineAndBarChart;
