export const checkIfMobile = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  if (/android/i.test(userAgent)) {
    return true;
  } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
    return true;
  } else {
    return false;
  }
};
