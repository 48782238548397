import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import { getContainerClient } from "../services/container";
import { useTranslation } from "react-i18next";
import { checkStatusList, tagsOptions } from "../shared/utils/utils";
import { LoadingScreen } from "./loader/LoadingScreen";
import Multiselect from "multiselect-react-dropdown";

const ContainerModalClient = ({
  data,
  displayClient,
  setDisplayClient,
  weightRef,
  newClient,
  setNewClient,
  handleSelectionChange,
  setClientCode,
  clientName,
  setClientName,
  setClientAddress,
  weight,
  setWeight,
  wasteCategoryName,
  setWasteCategoryName,
  wasteCategory,
  saveWeight,
  totalWeight,
  superContainer,
  isCharacterization,
  refreshAction,
  checkStatus,
  setCheckStatus,
  loading,
  tags,
  setTags,
  displayStatus,
  setDisplayStatus,
  displayTags,
  setDisplayTags,
  wasteName,
}) => {
  const { t, i18n } = useTranslation();
  const [clientList, setClientList] = useState([]);

  const fetchContainerClient = async (id) => {
    getContainerClient(id).then((response) => {
      if (response?.data && response?.data?.data) {
        const newClientList = response?.data?.data.reduce(
          (acc, client) => {
            const key = `${client.name}-${client.address}`;
            if (!acc.keys.has(key)) {
              acc.keys.add(key);
              acc.items.push(client);
            }
            return acc;
          },
          { keys: new Set(), items: [] },
        ).items;

        setClientList(newClientList);
      }
      return null;
    });
  };

  useEffect(
    () => {
      fetchContainerClient(
        superContainer && superContainer._id !== undefined
          ? superContainer._id
          : data._id,
      );
    },
    // eslint-disable-next-line
    [data, displayClient],
  );

  useEffect(() => {
    if (
      wasteCategoryName &&
      wasteCategoryName !== "{}" &&
      wasteCategoryName !== ""
    ) {
      return;
    }

    if (wasteName) {
      const category = wasteCategory.find((item) => item.label === wasteName);
      if (category) {
        const formattedCategory = JSON.stringify({
          id: category._id,
          wasteCategoryName: category.label,
          wasteCategoryCode: category.wasteCodeFull,
          wasteCategoryValue: category.value,
          wasteCategoryAuthorizedMaterial: category.authorizedMaterial,
          wasteCategoryDensity: category.density,
        });
        setWasteCategoryName(formattedCategory);
      }
    }
  }, [wasteCategoryName, wasteName, wasteCategory, setWasteCategoryName]);

  return (
    <Modal ref={weightRef} className="w-1/3" isOpen={displayClient}>
      <ModalHeader>
        <span className="text-blue">
          {newClient
            ? "Client"
            : isCharacterization
              ? t("Characterization")
              : displayStatus
                ? t("Status")
                : displayTags
                  ? t("Tags")
                  : ""}
        </span>
        <CloseIcon
          style={{
            color: "var(--gray)",
            cursor: "pointer",
          }}
          onClick={() => {
            if (!isCharacterization) {
              setDisplayTags(false);
              setDisplayStatus(false);
              if (refreshAction) {
                refreshAction();
              }
            }
            setDisplayClient(false);
          }}
        />
      </ModalHeader>
      <ModalBody>
        <div className="flex flex-col w-full">
          {!displayTags && !displayStatus && (
            <>
              {!newClient ? (
                <div className="flex flex-col">
                  <div className="w-full">
                    <span className="text-blue">Client</span>
                    <select
                      id="client"
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 mr-2"
                      onChange={(event) => handleSelectionChange(event)}
                    >
                      {clientList.map((item) =>
                        clientName === item.name ? (
                          <option
                            selected
                            key={item.id}
                            value={JSON.stringify(item)}
                          >
                            {item.name}{" "}
                            {`(${item?.address && item?.address !== " " && ![undefined, "undefined"].includes(item.address) ? item?.address : "-"})`}
                          </option>
                        ) : (
                          <option key={item.id} value={JSON.stringify(item)}>
                            {item.name}{" "}
                            {`(${item?.address && item?.address !== " " && ![undefined, "undefined"].includes(item.address) ? item?.address : "-"})`}
                          </option>
                        ),
                      )}
                    </select>
                  </div>
                  <p
                    class="text-center italic text-[#00A7A6] hover:text-gray-500 hover:underline cursor-pointer mt-2"
                    onClick={() => setNewClient(true)}
                  >
                    {t("Add a new client")} ?
                  </p>
                </div>
              ) : (
                <div className="flex flex-col">
                  <div className="mb-2">
                    <span className="text-blue">{t("Client name")}</span>
                    <input
                      onChange={(event) => setClientName(event.target.value)}
                      type="text"
                      id="weight"
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      required
                    />
                  </div>
                  <div className="mb-2">
                    <span className="text-blue">
                      {t("Identification reference")}
                    </span>
                    <input
                      onChange={(event) => setClientCode(event.target.value)}
                      type="text"
                      id="code"
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      required
                    />
                  </div>
                  <div className="mb-2">
                    <span className="text-blue">{t("Address")}</span>
                    <input
                      onChange={(event) => setClientAddress(event.target.value)}
                      type="text"
                      id="weight"
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      required
                    />
                  </div>
                </div>
              )}
              {!newClient && (
                <>
                  <div className="flex flex-col mb-3">
                    <div className="w-full">
                      <span className="text-blue">
                        {t("Weight")} {`(${t("tons")})`}
                      </span>
                      <input
                        onChange={(event) => setWeight(event.target.value)}
                        value={weight ? weight : ""}
                        type="text"
                        id="weight"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        required
                      />
                    </div>
                  </div>
                  <div className="flex flex-col mb-3">
                    <div className="w-full">
                      <span className="text-blue">{t("Waste name")}</span>
                      <select
                        id="client"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 mr-2"
                        onChange={(event) => {
                          setWasteCategoryName(event.target.value);
                        }}
                      >
                        <option value="" className="text-gray-300">
                          ---- {t("Please select")} ----
                        </option>
                        {wasteCategory.map((item) =>
                          wasteCategoryName !== "" &&
                          JSON.parse(wasteCategoryName).wasteCategoryName ===
                            item.label ? (
                            <option
                              selected
                              key={item._id}
                              value={JSON.stringify({
                                id: item._id,
                                wasteCategoryName: item.label,
                                wasteCategoryCode: item.wasteCodeFull,
                                wasteCategoryValue: item.value,
                                wasteCategoryAuthorizedMaterial:
                                  item.authorizedMaterial,
                                wasteCategoryDensity: item.density,
                              })}
                            >
                              {item.label}
                            </option>
                          ) : (
                            <option
                              key={item.id}
                              value={JSON.stringify({
                                wasteCategoryName: item.label,
                                wasteCategoryCode: item.wasteCodeFull,
                                wasteCategoryValue: item.value,
                                wasteCategoryAuthorizedMaterial:
                                  item.authorizedMaterial,
                                wasteCategoryDensity: item.density,
                              })}
                            >
                              {item.label}
                            </option>
                          ),
                        )}
                      </select>
                      <Link
                        to={{
                          pathname: "/waste",
                        }}
                        target="_blank"
                        class="text-center italic text-[#00A7A6] hover:text-gray-500 hover:underline cursor-pointer mt-2"
                      >
                        <div>{t("Set up a new waste category")} ?</div>
                      </Link>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
          {!isCharacterization && displayStatus && (
            <div className="flex flex-col mb-3">
              <div className="w-full">
                <span className="text-blue">{t("Status")}</span>
                <select
                  id="client"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 mr-2"
                  onChange={(event) => {
                    setCheckStatus(event.target.value);
                  }}
                >
                  <option value="" className="text-gray-300">
                    {t("Pas de statut")}
                  </option>
                  {checkStatusList.map((item, index) =>
                    item.value === checkStatus?.value ? (
                      <option selected key={index} value={JSON.stringify(item)}>
                        {i18n.language === "en" ? item.labelEn : item.labelFr}
                      </option>
                    ) : (
                      <option key={index} value={JSON.stringify(item)}>
                        {i18n.language === "en" ? item.labelEn : item.labelFr}
                      </option>
                    ),
                  )}
                </select>
              </div>
            </div>
          )}
          {!isCharacterization && displayTags && (
            <div className="flex flex-col mb-3">
              <div className="w-full">
                <span className="text-blue">{t("Tags")}</span>
                <Multiselect
                  options={tagsOptions}
                  displayValue={i18n.language === "en" ? "labelEn" : "labelFr"}
                  onSelect={(selectedList) => setTags(selectedList)}
                  onRemove={(selectedList) => setTags(selectedList)}
                  placeholder={t("Tags")}
                  showArrow={true}
                  hidePlaceholder={true}
                  selectedValues={tags}
                />
              </div>
            </div>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="w-full text-center justify-center">
          <button
            type="button"
            class="p-2 w-28 text-md font-medium text-white rounded-lg"
            style={{ backgroundColor: "#00A7A6" }}
            onClick={() =>
              saveWeight(
                superContainer && superContainer._id !== undefined
                  ? superContainer._id
                  : data._id,
                !isCharacterization && !displayTags && !displayStatus
                  ? true
                  : false,
              )
            }
          >
            {(totalWeight !== "" && !totalWeight) || displayClient
              ? t("Save")
              : t("Update")}
          </button>
        </div>
      </ModalFooter>
      {loading && <LoadingScreen />}
    </Modal>
  );
};

export default ContainerModalClient;
