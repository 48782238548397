import { useEffect, useState } from "react";
import axios from "axios";
import Loading from "../../../components/utils/Loading";
import {
  formattedColorMaterial,
  formattedMaterial,
  formattedWasteColor,
} from "../../../components/formatted/FormattedText";
import MultipleLineAndBarChart from "../../../components/charts/MultipleLineAndBarChart";
import { getWasteStatistic } from "../../../services/container";
import { useTranslation } from "react-i18next";

function mergeFluxes(initialObj, mergeConfig) {
  const newObj = {};

  // Copy initialObj to newObj
  Object.keys(initialObj).forEach((key) => {
    newObj[key] = initialObj[key];
  });

  // Merge the specified objects
  mergeConfig.forEach((merge) => {
    const { referenceName, possibleNames } = merge;

    // Initialize the merged object if it does not exist
    if (newObj[referenceName] === undefined) {
      newObj[referenceName] = [];
    }

    // Remove referenceName from possibleNames if it exists
    const referenceIndex = possibleNames.indexOf(referenceName);
    if (referenceIndex > -1) {
      possibleNames.splice(referenceIndex, 1);
    }

    // Loop through each object to be merged
    possibleNames.forEach((name) => {
      const obj = newObj[name];
      if (!obj) return;

      // Loop through each element in the object
      obj.forEach((elem) => {
        const existingElemIndex = newObj[referenceName].findIndex(
          (x) => x.flux === elem.flux,
        );
        if (existingElemIndex === -1) {
          // If element doesn't exist in the merged object, add it
          newObj[referenceName].push({ ...elem });
        } else {
          // Otherwise, merge the countAnomaly property
          newObj[referenceName][existingElemIndex].countAnomaly +=
            elem.countAnomaly;
        }
      });

      if (referenceName !== name) {
        // Remove the merged object from newObj
        delete newObj[name];
      }
    });

    if (newObj[referenceName].length === 0) {
      delete newObj[referenceName];
    }
  });

  return newObj;
}

const StatisticsChartComponent = ({
  periodHistoryStart,
  periodHistoryStop,
  keywordFilter,
  handleValidate,
  setHandleValidate,
  setOpenFilter,
}) => {
  const { t, i18n } = useTranslation();
  const [data, setData] = useState({});
  const [totalSortingErrorCurrent, setTotalSortingErrorCurrent] = useState(0);
  const [dataSortingError, setDataSortingError] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [tooManyReviews, setTooManyReviews] = useState({
    numberOfReviews: 0,
    maxNumberOfReviews: 100,
  });

  //Chart variable construction
  const dataSetsSortingErrorByFluxChart = [];
  const labelsByTimeChart = [];
  const dataSetsDistributionSortingError = [];

  useEffect(
    () => {
      setHandleValidate(true);
    },
    // eslint-disable-next-line
    [],
  );

  const fetchData = () => {
    setIsLoading(true);
    setOpenFilter(false);
    axios
      .all([
        getWasteStatistic({
          periodStart: periodHistoryStart,
          periodStop: periodHistoryStop,
          keywordFilter: JSON.stringify(keywordFilter.value),
        }),
      ])
      .then(
        axios.spread((responseWasteStatistics) => {
          if (responseWasteStatistics.status === 200) {
            setData(responseWasteStatistics.data.statisticsData.dataResult);
            const mergeConfig = JSON.parse(
              sessionStorage.getItem("materialsDescription"),
            ).map((v) => ({
              referenceName: v.referenceName,
              possibleNames: v.possibleNames,
            }));
            setDataSortingError(
              mergeFluxes(
                responseWasteStatistics.data.statisticsData.dataSortingError,
                mergeConfig,
              ),
            );

            setTotalSortingErrorCurrent(
              responseWasteStatistics.data.statisticsData.countAnomalies,
            );
            setHandleValidate(false);
            setIsLoading(false);
          }
        }),
      )
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 413) {
            setIsLoading(false);
            setTooManyReviews({
              numberOfReviews: error.response.data.numberOfReviews,
              maxNumberOfReviews: error.response.data.maxNumberOfReviews,
            });
          }
        }
        console.error("Error occurred:", error);
      });
  };

  useEffect(
    () => {
      if (handleValidate) {
        fetchData();
      }
    },
    // eslint-disable-next-line
    [handleValidate],
  );

  //loop data to get dataSetsSortingErrorByFluxChart and labelsByTimeChart
  Object.keys(data).forEach((flux) => {
    dataSetsSortingErrorByFluxChart.push({
      label: flux,
      data: data[flux].monthlyDistribution.map((item) => ({
        x: item.time,
        y: item.countAnomalies,
        z: flux,
      })),
      backgroundColor: formattedWasteColor(flux),
      type: "bar",
    });

    for (let i = 0; i < data[flux].monthlyDistribution.length; i++) {
      if (!labelsByTimeChart.includes(data[flux].monthlyDistribution[i].time)) {
        labelsByTimeChart.push(data[flux].monthlyDistribution[i].time);
      }
    }
    return null;
  });

  //loop dataSortingError to get dataSetsSortingErrorByTypeChart and materialSortingErrorByTypeChart
  Object.keys(dataSortingError).forEach((material) => {
    dataSetsDistributionSortingError.push({
      label: formattedMaterial(material, i18n.language),
      data: dataSortingError[material].map((flux) => ({
        x: flux.flux,
        y: ((flux.countAnomaly / totalSortingErrorCurrent) * 100).toFixed(1),
        z: formattedMaterial(material, i18n.language),
        count: flux.countAnomaly,
      })),
      backgroundColor: formattedColorMaterial(material),
      type: "bar",
    });

    return null;
  });

  return (
    <div>
      {!isLoading ? (
        tooManyReviews.numberOfReviews > 0 ? (
          <section className="flex flex-col bg-white rounded-lg my-2 py-48 text-center text-red-500">
            <span className="fas fa-exclamation-circle text-xl"></span>
            <span>
              {t("Too many reviews", {
                numberOfReviews: tooManyReviews.numberOfReviews,
                maxNumberOfReviews: tooManyReviews.maxNumberOfReviews,
              })}
            </span>
          </section>
        ) : Object.keys(data).length > 0 &&
          Object.keys(dataSortingError).length > 0 ? (
          <>
            <section className="flex flex-col min-lg:flex-row my-2">
              <article className="min-lg:w-1/2 bg-white p-3 rounded-lg min-lg:mr-2 my-2">
                <h4 className="font-semibold">
                  {t("Sorting error by waste category")}
                </h4>
                <div className="modal-item-chart grid justify-items-center">
                  <MultipleLineAndBarChart
                    stackedChart={true}
                    type="bar"
                    borderWidthChart={1}
                    labelsChart={labelsByTimeChart}
                    aspectRatioChart={1}
                    datasetsChart={dataSetsSortingErrorByFluxChart}
                    indexAxisChart="x"
                    maintainAspectRatioChart={false}
                    displayGrid={true}
                    displayTicks={true}
                    xAxisType={"time"}
                    reverseChart={false}
                    displayLegend={true}
                    displayTooltip={true}
                    messageLabelTooltip={t("sorting errors")}
                    unitValueChart=""
                    maxYAxis={null}
                  />
                </div>
              </article>

              <article className="min-lg:w-1/2 bg-white p-3 rounded-lg min-lg:ml-2 my-2">
                <div className="flex flex-row justify-between">
                  <h4 className="font-semibold">
                    {t("Types of sorting errors")}
                  </h4>
                </div>

                <div className="modal-item-chart grid justify-items-center">
                  <MultipleLineAndBarChart
                    stackedChart={true}
                    type="bar"
                    borderWidthChart={1}
                    labelsChart={[]}
                    aspectRatioChart={1}
                    datasetsChart={dataSetsDistributionSortingError}
                    indexAxisChart="x"
                    maintainAspectRatioChart={false}
                    displayGrid={true}
                    displayTicks={true}
                    xAxisType={"category"}
                    reverseChart={false}
                    displayLegend={true}
                    displayTooltip={true}
                    messageLabelTooltip={t("sorting errors")}
                    unitValueChart="%"
                    maxYAxis={100}
                    additionalInfo={true}
                  />
                </div>
              </article>
            </section>
          </>
        ) : (
          <section className="flex flex-col bg-white rounded-lg my-2 py-48 text-center text-cyan">
            <span className="fas fa-exclamation-triangle text-xl"></span>
            <span>{t("No anomalies for the current period")}</span>
          </section>
        )
      ) : (
        <div className="my-2">
          <Loading height={"55vh"} />
        </div>
      )}
    </div>
  );
};

export default StatisticsChartComponent;
