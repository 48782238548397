import { FormGroup, Input, Label } from "reactstrap";
import { handleFormChange } from "../../../components/modals/EditableTable";
import Loading from "../../../components/utils/Loading";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export const ContainerSiteAndContract = ({
  isLoading,
  sites,
  contracts,
  formData,
  setFormData,
}) => {
  const { t } = useTranslation();
  const [helpPositioning, setHelpPositioning] = useState(false);

  return isLoading ? (
    <div className="w-1/4">
      <Loading height={"45vh"} />
    </div>
  ) : (
    <section className="flex flex-col w-1/4 min-h-full">
      {/* AddressName */}
      <FormGroup className="flex flex-col w-full mb-3 ">
        <Label>{t("Affiliated site")}</Label>
        <div className="flex flex-col">
          <Input
            className="h-10 bg-white border border-gray-300 text-gray-600 rounded-lg focus:ring-blue-500 focus:border-blue-500 block"
            type="select"
            value={formData.addressId}
            onChange={(event) => handleFormChange(event, formData, setFormData)}
            name="addressId"
            secondfield="addressName"
          >
            <option value="unknown" key={`site-name-unknown`}>
              {t("Site not specified")}
            </option>

            {sites.map((site, index) => (
              <option
                value={site._id}
                secondvalue={site.name}
                key={`site-name-${site._id}`}
              >
                {site.name}
              </option>
            ))}
          </Input>
        </div>
      </FormGroup>

      {/* Positioning */}
      <FormGroup className="flex flex-col w-full mb-3 ">
        <div
          className="flex relative"
          onMouseEnter={() => setHelpPositioning(true)}
          onMouseLeave={() => setHelpPositioning(false)}
        >
          <Label>{t("Affiliation point description")}</Label>
          <i className="fas fa-info-circle text-grey text-sm mt-0 mx-2"></i>
          {helpPositioning && (
            <div className="absolute bottom-10 bg-yellow text-white p-2 rounded-lg shadow-lg z-10 w-96">
              <p>
                {t(
                  "Additional description for the address to identify the affiliation point",
                )}
                .
                <br />
                {t("Example")}:
                <ul class="list-disc" style={{ marginLeft: 18 }}>
                  <li>Quai 3</li>
                  <li>Plateforme A</li>
                </ul>
              </p>
            </div>
          )}
        </div>
        <div className="flex flex-col">
          <Input
            className="information-column-data"
            type="text"
            placeholder="Point de rattachement"
            value={formData.positioning}
            defaultValue={formData.positioning}
            onChange={(event) => handleFormChange(event, formData, setFormData)}
            name="positioning"
          />
        </div>
      </FormGroup>

      {/* AddressDestination */}
      <FormGroup className="flex flex-col w-full mb-2">
        <Label>{t("Destination site")}</Label>
        <div className="flex flex-col">
          <Input
            className="h-12 bg-white border border-gray-300 text-gray-600 rounded-lg focus:ring-blue-500 focus:border-blue-500 block"
            type="select"
            value={formData.addressDestinationId}
            onChange={(event) => handleFormChange(event, formData, setFormData)}
            name="addressDestinationId"
          >
            <option value="unknown" key={`site-name-unknown`}>
              {t("Site not specified")}
            </option>

            {sites.map((site, index) => (
              <option value={site._id} key={`site-name-${site._id}`}>
                {site.name}
              </option>
            ))}
          </Input>
        </div>
      </FormGroup>

      {/* ConfigurationAddress */}
      {formData.addressId === "unknown" ||
      formData.addressDestinationId === "unknown" ||
      !formData.addressId ||
      !formData.addressDestinationId ? (
        <FormGroup className="flex flex-col w-full mb-5">
          <Link
            to={{
              pathname: "/address",
              search: "?openCreate=true",
            }}
            target="_blank"
            className="flex my-2 py-2.5 justify-center bg-cyan rounded text-white "
          >
            <span className="p-1 text-center">{t("Add a new site")}​</span>
          </Link>
        </FormGroup>
      ) : (
        <FormGroup className="flex flex-col w-full mb-5">
          <Link
            to={{
              pathname: "/address",
            }}
            target="_blank"
            className="flex my-2 py-2.5 justify-center bg-cyan rounded text-white "
          >
            <span className="p-1 text-center">
              {t("Change the properties of a site")}
            </span>
          </Link>
        </FormGroup>
      )}

      {/* Contract */}
      <FormGroup className="flex flex-col w-full mb-2">
        <Label>{t("Contract")}</Label>
        <div className="flex flex-col">
          <Input
            className="h-12 bg-white border border-gray-300 text-gray-600 rounded-lg focus:ring-blue-500 focus:border-blue-500 block"
            type="select"
            value={formData.contract_id}
            onChange={(event) => handleFormChange(event, formData, setFormData)}
            name="contract_id"
            secondfield="collectorEmail"
          >
            <option value="unknown" key={`site-name-unknown`}>
              {t("Contract not provided")}
            </option>

            {contracts.map((contract, index) => (
              <option
                value={contract._id}
                secondvalue={contract.collectorEmail}
                key={`site-name-${contract._id}`}
              >
                {contract.name}
              </option>
            ))}
          </Input>
        </div>
      </FormGroup>

      {/* ConfigurationContract */}
      {formData.contract_id === "unknown" || !formData.contract_id ? (
        <FormGroup className="flex flex-col w-full mb-3">
          <Link
            to={{
              pathname: "/settings",
            }}
            target="_blank"
            className="flex my-2 py-2.5 justify-center bg-cyan rounded text-white "
          >
            <span className="p-1 text-center">{t("Add a new contract")}</span>
          </Link>
        </FormGroup>
      ) : (
        <FormGroup className="flex flex-col w-full mb-3">
          <Link
            to={{
              pathname: "/settings",
            }}
            target="_blank"
            className="flex my-2 py-2.5 justify-center bg-cyan rounded text-white "
          >
            <span className="p-1 text-center">
              {t("Change the properties of a contract")}
            </span>
          </Link>
        </FormGroup>
      )}
    </section>
  );
};
