import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { deleteCampaign } from "../../services/notification";
import { formattedDate } from "../../components/formatted/FormattedDateAndTime";

const CampaignDeleteModal = ({
  campaign,
  campaignDelete,
  setCampaignDelete,
  fetchCampaignsPeriods,
}) => {
  const { t, i18n } = useTranslation();

  const toggle = () => setCampaignDelete(false);

  const handleDelete = async () => {
    await deleteCampaign(campaign._id).then(async (res) => {
      if (res.status === 200) {
        await fetchCampaignsPeriods();
        setCampaignDelete(false);
      }
    });
  };

  return (
    <Modal isOpen={campaignDelete} toggle={toggle} className="w-3/6">
      <ModalHeader>
        <div className="w-full flex flex-end">
          <CloseIcon
            style={{
              color: "var(--gray)",
              cursor: "pointer",
            }}
            onClick={() => setCampaignDelete(false)}
          />
        </div>
      </ModalHeader>
      <div>
        <ModalBody>
          <div className="text-center mt-3">
            <span>
              {`Voulez-vous vraiment supprimer la campagne de tri ${`du ${formattedDate(
                campaign.start,
                i18n.language,
                true,
              )} au ${formattedDate(campaign.stop, i18n.language, true)}`}`}
            </span>
          </div>
        </ModalBody>
        <ModalFooter className="information-footer-modal">
          <div className="w-full flex flex-end gap-2">
            <Button
              onClick={() => {
                handleDelete();
              }}
            >
              {t("Validate")}
            </Button>
            <Button
              onClick={() => {
                setCampaignDelete(false);
              }}
            >
              {t("Cancel")}
            </Button>
          </div>
        </ModalFooter>
      </div>
    </Modal>
  );
};

export default CampaignDeleteModal;
