import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CloseIcon from "@mui/icons-material/Close";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Input,
  FormGroup,
  Form,
  Label,
} from "reactstrap";
import {
  handleAddFormSubmit,
  handleFormChange,
} from "../../components/modals/EditableTable";
import PhoneInput from "react-phone-number-input";
import { isValidPhone } from "../../components/utils/ValidatePhone";
import axios from "axios";
import { Grid } from "@mui/material";
import { InfosComponent } from "../../components/InfosComponent";
import Multiselect from "multiselect-react-dropdown";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { isVerifyPassword } from "./CheckPassword";

const UserAccessCreate = ({
  addFormData,
  setAddFormData,
  defaultData,
  tableData,
  setTableData,
  totalEntry,
  API,
  token,
  setTotalEntry,
  modalCreate,
  setModalCreate,
  corporation,
}) => {
  const path = "user";
  const { t } = useTranslation();
  const [sites, setSites] = useState([]);
  const [errorForm, setErrorForm] = useState("");
  const [secure, setSecure] = useState(true);
  const [secureConfirm, setSecureConfirm] = useState(true);
  const toggle = () => setModalCreate(!modalCreate);
  const multiselectAddressRef = useRef();

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .all([
          axios.get(`${API}address/getAddressNameList`, {
            headers: { authorization: `Bearer ${token}` },
          }),
        ])
        .then(
          axios.spread((responseSite) => {
            if (responseSite.status === 200) {
              setSites(responseSite.data.addressNameList);
            }
          }),
        );
    };
    fetchData();
  }, [API, token]);

  const handleAuthorizedAddresses = (addressesToAdd) => {
    const newAddFormData = { ...addFormData };
    newAddFormData.authorizedAddresses = addressesToAdd.map(
      (addressId) => addressId._id,
    );
    setAddFormData(newAddFormData);
  };

  return (
    <Modal isOpen={modalCreate} toggle={toggle}>
      <ModalHeader>
        <h2 className="mb-0">{t("Create access")}</h2>
        <CloseIcon
          style={{
            color: "var(--gray)",
            cursor: "pointer",
          }}
          onClick={() => {
            setModalCreate(false);
            setAddFormData(defaultData);
          }}
        />
      </ModalHeader>

      <Form
        onSubmit={async (event) => {
          event.preventDefault();
          if (
            isVerifyPassword(
              corporation.corporation.googleSSORequired,
              addFormData.password,
              addFormData.passwordConfirm,
              setErrorForm,
              t,
            )
          ) {
            const response = await handleAddFormSubmit(
              event,
              addFormData,
              setAddFormData,
              defaultData,
              tableData,
              setTableData,
              totalEntry,
              setTotalEntry,
              path,
              setErrorForm,
              setErrorForm,
            );
            if (response.status === 200) {
              setModalCreate(false);
              toast.success(t("The user has been successfully added"), {
                className: "custom-toast",
              });
            }
          }
        }}
        className="relative"
      >
        <ModalBody>
          <section>
            {addFormData.mailRecipient && (
              <div className="absolute left-1/2 top-2 transform -translate-x-1/2">
                <InfosComponent
                  message={t(
                    "To ensure the delivery of your first emails, check your spam folder and adjust the settings",
                  )}
                />
              </div>
            )}

            <section
              className={`error-form ${errorForm ? "display-error absolute left-1/2 top-14 transform -translate-x-1/2" : ""}`}
            >
              <p>{errorForm}</p>
            </section>

            <article className="information-bloc mt-20">
              <FormGroup className="information-line">
                <Label className="information-label">{t("First name")}</Label>
                <Input
                  className="information-column-data"
                  type="text"
                  required="required"
                  placeholder={t("First name")}
                  value={addFormData.firstName}
                  onChange={(event) =>
                    handleFormChange(event, addFormData, setAddFormData)
                  }
                  name="firstName"
                />
              </FormGroup>

              <FormGroup className="information-line">
                <Label className="information-label">{t("Name")}</Label>
                <Input
                  className="information-column-data"
                  type="text"
                  required="required"
                  placeholder={t("Name")}
                  value={addFormData.lastName}
                  onChange={(event) =>
                    handleFormChange(event, addFormData, setAddFormData)
                  }
                  name="lastName"
                />
              </FormGroup>

              <FormGroup className="information-line">
                <Label className="information-label">{t("Role")}</Label>
                <select
                  type="select"
                  className="information-select"
                  placeholder={t("Choose a role")}
                  name="role"
                  onChange={(event) =>
                    handleFormChange(event, addFormData, setAddFormData)
                  }
                >
                  <option value="client">Client</option>
                  <option value="driver">{t("Driver")}</option>
                  <option value="logistic">{t("Logistics")}</option>
                  <option value="owner">{t("Manager")}</option>
                </select>
              </FormGroup>

              <FormGroup className="information-line">
                <Label className="information-label">
                  {t("Receive alerts by email")}
                </Label>
                <Input
                  className="information-data-check"
                  type="checkbox"
                  checked={addFormData.mailRecipient}
                  onChange={(event) =>
                    handleFormChange(event, addFormData, setAddFormData)
                  }
                  name="mailRecipient"
                />
              </FormGroup>

              <FormGroup className="information-line">
                <Label className="information-label">
                  {t("Receive alerts by SMS")}
                </Label>
                <Input
                  className="information-data-check"
                  type="checkbox"
                  checked={addFormData.smsRecipient}
                  onChange={(event) =>
                    handleFormChange(event, addFormData, setAddFormData)
                  }
                  name="smsRecipient"
                />
              </FormGroup>
            </article>

            <article className="information-bloc">
              <h3>{t("Coordinates")}</h3>

              <FormGroup className="information-line">
                <Label className="information-label">{t("Phone")}</Label>
                <PhoneInput
                  placeholder="Enter phone number"
                  defaultCountry="FR"
                  value=""
                  onChange={(number) => {
                    if (number === undefined) {
                      number = "";
                    }
                    if (isValidPhone(number, setErrorForm)) {
                      setErrorForm("");
                      const data = addFormData;
                      data.phone = number;
                      setAddFormData(data);
                    }
                  }}
                  className="flex row w-full form-control"
                />
              </FormGroup>

              <FormGroup className="information-line">
                <Label className="information-label">
                  {t("Email address")}
                </Label>
                <Input
                  className="information-data"
                  type="text"
                  name="email"
                  value={addFormData.email || ""} // Ensure the value is controlled
                  onChange={(event) => {
                    const value = event.target.value;

                    // Remove all spaces from the email input
                    const cleanedValue = value.replace(/\s/g, ""); // Remove all spaces
                    setAddFormData({ ...addFormData, email: cleanedValue }); // Update state
                  }}
                />
              </FormGroup>
            </article>
            <article className="information-bloc">
              <h3>{t("My sites")}</h3>
              <FormGroup className="w-full">
                <Grid container columns={{ xs: 12 }}>
                  <Grid item xs={3.4}>
                    <Label>Sites</Label>
                  </Grid>
                  <Grid item xs={8.6}>
                    <Multiselect
                      options={sites}
                      ref={multiselectAddressRef}
                      placeholder={t("Choose my addresses")}
                      displayValue="name"
                      onSelect={(selectedList) =>
                        handleAuthorizedAddresses(
                          selectedList.map((option) => option),
                        )
                      }
                      onRemove={(selectedList) => {
                        handleAuthorizedAddresses(
                          selectedList.map((option) => option),
                        );
                      }}
                      showArrow={true}
                      hidePlaceholder={true}
                      showCheckbox={true}
                      closeOnSelect={false}
                      style={{
                        searchBox: {
                          padding: "3px !important",
                          display: "block",
                          overflow: "visible",
                          height: "auto",
                          borderRadius: "4px",
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </FormGroup>
            </article>
            <article className="information-bloc">
              {!corporation.corporation.googleSSORequired && (
                <>
                  <h3>{t("Create a password")}</h3>
                  <FormGroup className="information-line">
                    <Label className="information-label">
                      {t("New password")}
                    </Label>
                    <Input
                      className="information-data"
                      type={secure ? "password" : "text"}
                      name="password"
                      onChange={(event) =>
                        handleFormChange(event, addFormData, setAddFormData)
                      }
                    />
                    <FontAwesomeIcon
                      className="signin-icon-eye"
                      size={24}
                      icon={secure ? "eye-slash" : "eye"}
                      color="#828899"
                      onClick={() => setSecure(!secure)}
                    />
                  </FormGroup>

                  <FormGroup className="information-line">
                    <Label className="information-label">
                      {t("Confirm password")}
                    </Label>
                    <Input
                      className="information-data"
                      type={secureConfirm ? "password" : "text"}
                      name="passwordConfirm"
                      onChange={(event) =>
                        handleFormChange(event, addFormData, setAddFormData)
                      }
                    />
                    <FontAwesomeIcon
                      className="signin-icon-eye"
                      size={24}
                      icon={secureConfirm ? "eye-slash" : "eye"}
                      color="#828899"
                      onClick={() => setSecureConfirm(!secureConfirm)}
                    />
                  </FormGroup>
                </>
              )}
            </article>
          </section>
        </ModalBody>

        <ModalFooter className="information-footer-modal">
          <button className="button-submit" type="submit">
            {t("Save")}
          </button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default UserAccessCreate;
