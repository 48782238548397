import React from "react";
import { useHistory } from "react-router-dom";
import SkeletonLoader from "../../components/utils/SkeletonLoader";
import { truncateText } from "../../components/formatted/FormattedText";
import containerIcon from "../../static/assets/ContainerIcon.jpg";

export const DashboardGaugeAddress = ({ isLoading, addresses }) => {
  const history = useHistory();

  const handleAddressRowClick = (addressName) => {
    history.push({
      pathname: `/containers`,
      state: {
        addressF: addressName,
      },
    });
  };

  return (
    <section className="dashboard-card">
      {isLoading ? (
        <SkeletonLoader />
      ) : (
        <article className="dashboard-card-top">
          <div className="address-container" style={{ marginTop: "15px" }}>
            {addresses.map((address) => (
              <div
                key={address._id}
                className="address-button"
                onClick={() => handleAddressRowClick(address.addressName)}
              >
                <div className="address-text-container">
                  <div className="address-text">
                    {truncateText(address.addressName, 40)}
                  </div>
                </div>
                <div className="info-container">
                  <img
                    src={containerIcon}
                    alt="Container Icon"
                    style={{
                      height: 10,
                      verticalAlign: "middle",
                      marginRight: 3,
                    }}
                  />
                  <div className="container-count">{address.count}</div>
                </div>
              </div>
            ))}
          </div>
        </article>
      )}
    </section>
  );
};
