import { useTranslation } from "react-i18next";

const CampaignHeader = () => {
  const { t } = useTranslation();

  return (
    <article className="flex flex-row w-full bg-white mt-3 pt-2.5 pb-2 px-2 rounded-md">
      <h6 className="w-2/12 font-semibold">{t("Notification")}</h6>
      <h6 className="w-1/12 font-semibold">{t("Flux")}</h6>
      <h6 className="w-1/12 font-semibold">{t("Quai")}</h6>
      <h6 className="w-2/12 font-semibold">{t("Traitement")}</h6>
      <h6 className="w-2/12 font-semibold">{t("Type d'erreur")}</h6>
      <h6 className="w-1/12 font-semibold">{t("Nombres")}</h6>
      <h6 className="w-1/12 font-semibold">{t("Retrait")}</h6>
      <h6 className="w-2/12 font-semibold">{t("Motif du non retrait")}</h6>
    </article>
  );
};

export default CampaignHeader;
