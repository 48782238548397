import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";
import Loading from "../../components/utils/Loading";
import useImage from "use-image";
import { Stage, Layer, Image } from "react-konva";
import { azureSASTokenToUrl } from "../../components/formatted/FormattedAzureSasToken";
import { useTranslation } from "react-i18next";

const ChartCamera = ({ token, API }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [imageUrl, setImageUrl] = useState("");
  const [imageHeight, setImageHeight] = useState(0);
  const [imageWidth, setImageWidth] = useState(0);
  const [metaData, setMetaData] = useState({ name: "" });

  let imageScale = (0.35 * window.innerWidth) / imageWidth;

  const CameraImage = ({ src, imageScale, imageWidth, imageHeight }) => {
    const [image] = useImage(azureSASTokenToUrl(src));
    return (
      <Image
        image={image}
        width={imageWidth * imageScale}
        height={imageHeight * imageScale}
      />
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .all([
          axios.get(`${API}camera/${id}`, {
            headers: {
              authorization: "Bearer " + token,
            },
          }),
        ])
        .then(
          axios.spread((...responses) => {
            setImageUrl(responses[0].data.data.image_url);
            setImageWidth(responses[0].data.data.imageAnnotation.imageWidth);
            setImageHeight(responses[0].data.data.imageAnnotation.imageHeight);
            setMetaData(responses[0].data.data);
            setIsLoading(false);
          }),
        );
    };

    fetchData();
  }, [token, API, id]);

  return isLoading ? (
    <Loading />
  ) : (
    <div className="base">
      <h1>
        {metaData._id.includes("_")
          ? `${t("Sensor")} ${metaData._id.split("_").slice(-2, -1)[0].slice(-4).toUpperCase()} ${metaData._id.endsWith("_l") ? "Left" : "Right"}`
          : `${t("Sensor")} ${metaData._id.slice(-4).toUpperCase()}`}
      </h1>

      <Row sm="2">
        <Col>
          <Card>
            <CardHeader>{t("Camera visual")}</CardHeader>
            <CardBody className="text-center">
              <Stage
                width={imageWidth * imageScale}
                height={imageHeight * imageScale}
              >
                <Layer>
                  {imageUrl !== "" ? (
                    <CameraImage
                      src={imageUrl}
                      imageScale={imageScale}
                      imageWidth={imageWidth}
                      imageHeight={imageHeight}
                    />
                  ) : null}
                </Layer>
              </Stage>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ChartCamera;
