import { Image, Page, Text, View } from "@react-pdf/renderer";
import { ActivityPdfStyleSheet } from "../styles/ActivityPdfStyleSheet";
import logo from "../../../static/assets/logo_rvg.jpg";
import { formattedDateDocument } from "../../formatted/FormattedDateAndTime";
import { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { CoverPageStyleSheet } from "../styles/CoverPageStyleSheet";
import { useTranslation } from "react-i18next";

export const CoverPageComponent = ({ periodStart, periodStop }) => {
  const { t, i18n } = useTranslation();
  const [userData, setUserData] = useState({});
  const [corporationName, setCorporationName] = useState(null);

  const createdDate = new Date();

  useEffect(() => {
    async function fetchData() {
      const API = process.env.REACT_APP_API_BACKEND;
      const token = Cookies.get("userToken");
      const stored = localStorage.getItem("user");
      setUserData(JSON.parse(stored));

      await axios
        .get(`${API}corporation/account`, {
          headers: {
            authorization: "Bearer " + token,
          },
        })
        .then((response) => {
          setCorporationName(response.data.corporation.corporationName);
        })
        .catch((error) => {
          console.error(error);
        });
    }
    fetchData();
  }, []);

  return (
    <Page size="A4" style={ActivityPdfStyleSheet.page} orientation="landscape">
      <Image src={logo} style={CoverPageStyleSheet.logo} />

      <View style={CoverPageStyleSheet.coverTitle}>
        <Text style={CoverPageStyleSheet.title}>
          {t("My history").toUpperCase()} : {t("Activity").toUpperCase()}
        </Text>
        <Text style={CoverPageStyleSheet.secondTitle}>
          {t("Period")}: {formattedDateDocument(periodStart)}{" "}
          {i18n.language === "en" ? "to" : "au"}{" "}
          {formattedDateDocument(periodStop)}
        </Text>
      </View>

      <View style={CoverPageStyleSheet.coverTitle}>
        <Text style={CoverPageStyleSheet.thirdTitle}>
          {t("Company")}: {corporationName}
        </Text>
        <Text style={CoverPageStyleSheet.thirdTitle}>
          {t("Edition date")}: {formattedDateDocument(createdDate)}
        </Text>
        <Text style={CoverPageStyleSheet.thirdTitle}>
          {t("User")}: {userData.firstName} {userData.lastName}
        </Text>
      </View>
    </Page>
  );
};
