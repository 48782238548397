import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CloseIcon from "@mui/icons-material/Close";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  Input,
  FormGroup,
  Form,
} from "reactstrap";
import {
  handleEditFormCancel,
  handleEditFormSubmit,
  handleFormChange,
} from "../../components/modals/EditableTable";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { isValidPhone } from "../../components/utils/ValidatePhone";
import Cookie from "js-cookie";
import Cookies from "js-cookie";
import axios from "axios";
import { Grid } from "@mui/material";
import { InfosComponent } from "../../components/InfosComponent";
import Multiselect from "multiselect-react-dropdown";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { isVerifyPassword } from "./CheckPassword";

const UserAccessEdit = ({
  tableData,
  setTableData,
  editFormData,
  setEditFormData,
  modalEdit,
  setModalEdit,
  corporation,
}) => {
  const path = "access";
  const API = process.env.REACT_APP_API_BACKEND;
  const token = Cookies.get("userToken");
  const { t } = useTranslation();
  const [sites, setSites] = useState([]);
  const [defaultValue, setDefaultValue] = useState([]);
  const [errorForm, setErrorForm] = useState("");
  const [disabledChangePassword, setDisabledChangePassword] = useState(true);
  const [adminUserConnected] = useState(
    Cookie.get("userAdmin") === "true" ? true : false,
  );
  const [secure, setSecure] = useState(true);
  const [secureConfirm, setSecureConfirm] = useState(true);
  const toggle = () => setModalEdit(!modalEdit);
  const multiselectAddressRef = useRef();

  const accessDisable = () => {
    return !(adminUserConnected === true || token === editFormData.token);
  };

  useEffect(
    () => {
      const fetchData = async () => {
        await axios
          .all([
            axios.get(`${API}address/getAddressNameList`, {
              headers: { authorization: `Bearer ${token}` },
            }),
          ])
          .then(
            axios.spread((responseSite) => {
              if (responseSite.status === 200) {
                setSites(responseSite.data.addressNameList);
                let newAddress = [];
                for (const address of responseSite.data.addressNameList) {
                  if (editFormData.authorizedAddresses.includes(address._id)) {
                    newAddress.push(address);
                  }
                }
                setDefaultValue(newAddress);
              }
            }),
          );
      };
      fetchData();
    },
    // eslint-disable-next-line
    [API, token],
  );

  useEffect(() => {
    const searchWrapper = document.querySelector(".search-wrapper");
    if (searchWrapper) {
      searchWrapper.classList.remove("search-wrapper");
    }
  }, []);

  const handleAuthorizedAddresses = (addressesToAdd) => {
    const newEditFormData = { ...editFormData };
    newEditFormData.authorizedAddresses = addressesToAdd.map(
      (addressId) => addressId._id,
    );
    const newDefaultValue = addressesToAdd.map((addressId) => addressId);
    setEditFormData(newEditFormData);
    setDefaultValue(newDefaultValue);
  };

  return (
    <Modal isOpen={modalEdit} toggle={toggle}>
      <ModalHeader>
        <h2 className="mb-0">{t("Edit access")}</h2>
        <CloseIcon
          style={{
            color: "var(--gray)",
            cursor: "pointer",
          }}
          onClick={() => {
            handleEditFormCancel(setModalEdit);
          }}
        />
      </ModalHeader>

      <Form
        onSubmit={async (event) => {
          event.preventDefault();
          if (
            isVerifyPassword(
              corporation.corporation.googleSSORequired,
              editFormData.password,
              editFormData.passwordConfirm,
              setErrorForm,
              t,
            )
          ) {
            const response = await handleEditFormSubmit(editFormData, path);

            if (response.status === 200) {
              const newData = [...tableData];
              const index = tableData.findIndex(
                (elem) => elem._id === editFormData._id,
              );
              newData[index] = editFormData;
              setTableData(newData);
              setModalEdit(false);
              toast.success(t("The user has been successfully updated"), {
                className: "custom-toast",
              });
            } else {
              setErrorForm(response.data.error);
            }
          }
        }}
        className="relative"
      >
        <ModalBody>
          <section>
            {editFormData.mailRecipient && (
              <div className="absolute left-1/2 top-2 transform -translate-x-1/2">
                <InfosComponent
                  message={t(
                    "To ensure the delivery of your first emails, check your spam folder and adjust the settings",
                  )}
                />
              </div>
            )}

            <section
              className={`error-form ${errorForm ? "display-error absolute left-1/2 top-14 transform -translate-x-1/2" : ""}`}
            >
              <p>{errorForm}</p>
            </section>

            <article className="information-bloc mt-20">
              <FormGroup className="information-line">
                <Label className="information-label">{t("Name")}</Label>
                <Input
                  className="information-data"
                  type="text"
                  placeholder={t("Name")}
                  value={editFormData.lastName}
                  name="lastName"
                  onChange={(event) =>
                    handleFormChange(event, editFormData, setEditFormData)
                  }
                  // disabled={accessDisable()}
                />
              </FormGroup>
              <FormGroup className="information-line">
                <Label className="information-label">{t("First name")}</Label>
                <Input
                  className="information-data"
                  type="text"
                  placeholder={t("First name")}
                  value={editFormData.firstName}
                  name="firstName"
                  onChange={(event) =>
                    handleFormChange(event, editFormData, setEditFormData)
                  }
                  // disabled={accessDisable()}
                />
              </FormGroup>
              <FormGroup className="information-line">
                <Label className="information-label">{t("Role")}</Label>
                <select
                  type="select"
                  className="information-select"
                  placeholder={t("Choose a role")}
                  value={editFormData.role}
                  name="role"
                  onChange={(event) =>
                    handleFormChange(event, editFormData, setEditFormData)
                  }
                  // disabled={accessDisable()}
                >
                  <option value="client">Client</option>
                  <option value="driver">{t("Driver")}</option>
                  <option value="logistic">{t("Logistics")}</option>
                  <option value="owner">{t("Manager")}</option>
                </select>
              </FormGroup>

              <FormGroup className="information-line">
                <Label className="information-label">
                  {t("Receive alerts by email")}
                </Label>
                <Input
                  className="information-data-check"
                  type="checkbox"
                  checked={editFormData.mailRecipient}
                  onChange={(event) =>
                    handleFormChange(event, editFormData, setEditFormData)
                  }
                  name="mailRecipient"
                />
              </FormGroup>
              <FormGroup className="information-line">
                <Label className="information-label">
                  {t("Receive alerts by SMS")}
                </Label>
                <Input
                  className="information-data-check"
                  type="checkbox"
                  checked={editFormData.smsRecipient}
                  onChange={(event) =>
                    handleFormChange(event, editFormData, setEditFormData)
                  }
                  name="smsRecipient"
                />
              </FormGroup>
            </article>
            <article className="information-bloc">
              <h3>{t("Coordinates")}</h3>
              <FormGroup className="information-line">
                <Label className="information-label">{t("Phone")}</Label>
                <PhoneInput
                  placeholder="Enter phone number"
                  defaultCountry="FR"
                  value={editFormData.phone}
                  onChange={(number) => {
                    if (number === undefined) {
                      number = "";
                    }
                    if (isValidPhone(number, setErrorForm)) {
                      setErrorForm("");
                      const data = editFormData;
                      data.phone = number;
                      setEditFormData(data);
                    }
                  }}
                  className="flex row w-full form-control"
                />
              </FormGroup>

              <FormGroup className="information-line">
                <Label className="information-label">
                  {t("Email address")}
                </Label>
                <Input
                  className="information-data"
                  type="text"
                  value={editFormData.email}
                  name="email"
                  onChange={(event) =>
                    handleFormChange(event, editFormData, setEditFormData)
                  }
                  disabled={true}
                ></Input>
              </FormGroup>
            </article>
            <article className="information-bloc">
              <h3>{t("My sites")}</h3>
              <FormGroup className="w-full">
                <Grid container columns={{ xs: 12 }}>
                  <Grid item xs={3.4}>
                    <Label>Sites</Label>
                  </Grid>
                  <Grid item xs={8.6}>
                    <Multiselect
                      options={sites}
                      ref={multiselectAddressRef}
                      selectedValues={defaultValue}
                      placeholder={t("Choose my addresses")}
                      displayValue="name"
                      onSelect={(selectedList) =>
                        handleAuthorizedAddresses(
                          selectedList.map((option) => option),
                        )
                      }
                      onRemove={(selectedList) => {
                        handleAuthorizedAddresses(
                          selectedList.map((option) => option),
                        );
                      }}
                      showArrow={true}
                      hidePlaceholder={true}
                      showCheckbox={true}
                      closeOnSelect={false}
                      style={{
                        searchBox: {
                          padding: "3px !important",
                          display: "block",
                          overflow: "visible",
                          height: "auto",
                          borderRadius: "4px",
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </FormGroup>
            </article>
            {!corporation.corporation.googleSSORequired && (
              <article className="information-bloc">
                <div className="change-password">
                  <h3>{t("Would you like to change your password")}?</h3>
                  <input
                    className="checkbox-change-password"
                    type="checkbox"
                    onChange={(e) =>
                      setDisabledChangePassword(!e.target.checked)
                    }
                    disabled={accessDisable()}
                  />
                </div>
                <FormGroup className="information-line">
                  <Label className="information-label">
                    {t("New password")}
                  </Label>
                  <Input
                    className="information-data"
                    type={secure ? "password" : "text"}
                    name="password"
                    disabled={disabledChangePassword}
                    onChange={(event) =>
                      handleFormChange(event, editFormData, setEditFormData)
                    }
                    value={editFormData.password}
                  />
                  {!disabledChangePassword ? (
                    <FontAwesomeIcon
                      className="signin-icon-eye"
                      size={24}
                      icon={secure ? "eye-slash" : "eye"}
                      color="#828899"
                      onClick={() => setSecure(!secure)}
                    />
                  ) : null}
                </FormGroup>
                <FormGroup className="information-line">
                  <Label className="information-label">
                    {t("Confirm password")}
                  </Label>
                  <Input
                    className="information-data"
                    type={secureConfirm ? "password" : "text"}
                    name="passwordConfirm"
                    disabled={disabledChangePassword}
                    onChange={(event) =>
                      handleFormChange(event, editFormData, setEditFormData)
                    }
                    value={editFormData.passwordConfirm}
                  />
                  {!disabledChangePassword ? (
                    <FontAwesomeIcon
                      className="signin-icon-eye"
                      size={24}
                      icon={secureConfirm ? "eye-slash" : "eye"}
                      color="#828899"
                      onClick={() => setSecureConfirm(!secureConfirm)}
                    />
                  ) : null}
                </FormGroup>
              </article>
            )}
          </section>
        </ModalBody>
        {/* {!accessDisable() ? */}
        <ModalFooter className="information-footer-modal">
          <button className="button-submit" type="submit">
            {t("Save")}
          </button>
        </ModalFooter>
        {/* // :null } */}
      </Form>
    </Modal>
  );
};

export default UserAccessEdit;
