import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import Cookies from "js-cookie";
import { updateUserFcmToken } from "./services/users";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const requestFcmToken = async () => {
  const currentToken = await getToken(messaging, {
    vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
  });
  if (currentToken) {
    const userAccess = await updateUserFcmToken({
      fcmToken: currentToken,
      notificationRecipient: true,
    });
    localStorage.setItem("user", JSON.stringify(userAccess.data));
    Cookies.set("fcmToken", currentToken, { secure: true });
  }
};

export const requestPermission = async (setLoading) => {
  const permission = await Notification.requestPermission();
  setLoading(true);
  if (permission === "granted") {
    await requestFcmToken();
  } else {
    await updateUserFcmToken({ fcmToken: "", notificationRecipient: false });
    Cookies.remove("fcmToken");
  }
};
