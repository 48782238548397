import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUserData } from "../services/users";
import { languageData } from "../shared/utils/utils";
import fr from "../static/assets/fr.png";
import i18n from "i18next";

const initialState = {
  data: null,
  status: "idle",
  error: null,
};

export const fetchUserLanguage = createAsyncThunk("language", async (id) => {
  const response = await getUserData(id);
  if (response?.data?.user?.language) {
    const language = response.data.user.language;
    localStorage.setItem("language", language);
    i18n.changeLanguage(language);

    return languageData.filter((item) => item.value === language)[0];
  } else {
    return { label: "Français", value: "fr", flag: fr };
  }
});

export const languageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserLanguage.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUserLanguage.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchUserLanguage.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});
