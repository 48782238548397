import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import {
  formattedFillingDeliveryColor,
  formattedWasteColor,
} from "../../components/formatted/FormattedText";
import { sinceDate } from "../../components/formatted/FormattedDateAndTime";
import Cookie from "js-cookie";
import { DashboardGaugeAddress } from "./DashboardGaugeAddress";
import { DashboardGaugeCharacterization } from "./DashboardGaugeCharacterization";
import { DashboardGaugeCo2 } from "./DashboardGaugeCo2";
import { DashboardGaugeContainer } from "./DashboardGaugeContainer";
import { DashboardGaugeSortingErrors } from "./DashboardGaugeSortingErrors";
import { DashboardGaugeOnSiteVolume } from "./DashboardGaugeOnSiteVolume";
import { useTranslation } from "react-i18next";
import DropdownSortable from "./DashboardDropdown";

export const DashboardIndicators = ({
  user,
  saveUser,
  countContainers,
  setCountContainers,
  countAnomalies,
  setCountAnomalies,
  setDataMaps,
  arrayFillingDelivery,
  setArrayFillingDelivery,
  setFillingRateDelivery,
  isLoading,
  setIsLoading,
  setMeanFilling,
  totalVolume,
  setTotalVolume,
  totalVolumeUsed,
  setTotalVolumeUsed,
  typeContainersDelivery,
  setTypeContainersDelivery,
  wasteErrorDelivery,
  setWasteErrorDelivery,
  wasteDelivery,
  setWasteDelivery,
  wasteCategory,
  dataMaps,
  characterization,
  setCharacterization,
}) => {
  const API = process.env.REACT_APP_API_BACKEND;
  const token = Cookie.get("userToken");
  const { t, i18n } = useTranslation();

  const [licenseEndDate, setLicenseEndDate] = useState(null);
  const [characterizationCredits, setCharacterizationCredits] = useState(0);
  const [addresses, setAddresses] = useState([]);

  const defaultDashboardConfiguration = [
    { id: "sortingError", label: "Sorting Error", selected: true },
    { id: "containerState", label: "Container State", selected: true },
    { id: "carbonFootprint", label: "Carbon Footprint", selected: true },
    { id: "characterization", label: "Characterization", selected: true },
    { id: "onSiteVolume", label: "On Site Volume", selected: true },
    { id: "address", label: "Address", selected: true },
  ];

  const [displayColumns, setDisplayColumns] = useState(() => {
    if (!user || !user.dashboardConfiguration) {
      return defaultDashboardConfiguration;
    }
    return user.dashboardConfiguration.length > 0
      ? user.dashboardConfiguration
      : defaultDashboardConfiguration;
  });

  useEffect(
    () => {
      if (dataMaps.length > 0) {
        for (const dataMap of dataMaps) {
          if (dataMap.characterization) {
            setCharacterization(true);
          }
        }
      }
    },
    // eslint-disable-next-line
    [dataMaps],
  );

  const fetchData = useCallback(
    () => {
      axios
        .all([
          axios.get(`${API}dashboard`, {
            headers: { authorization: `Bearer ${token}` },
          }),
          axios.get(`${API}settings`, {
            headers: { authorization: `Bearer ${token}` },
          }),
        ])
        .then(
          axios.spread((response, responseSettings) => {
            if (response.status === 200 && responseSettings.status === 200) {
              setDataMaps(response.data.lastDataContainers);

              const groupedByAddressId =
                response.data.lastDataContainers.reduce((acc, container) => {
                  const { addressId, addressName } = container;

                  if (!acc.has(addressId)) {
                    acc.set(addressId, { addressName, count: 1 });
                  } else {
                    acc.get(addressId).count += 1;
                  }

                  return acc;
                }, new Map());

              setAddresses([...groupedByAddressId.values()]);
              setMeanFilling(response.data.meanFilling);
              setTotalVolume(response.data.totalVolume);
              setTotalVolumeUsed(response.data.totalVolumeUsed);
              setTypeContainersDelivery(response.data.typeContainersDelivery);
              setWasteDelivery(response.data.wasteDelivery);
              setCharacterizationCredits(
                responseSettings.data.settings.characterizationCredits,
              );
              setLicenseEndDate(responseSettings.data.settings.licenseEndDate);
              setIsLoading(false);
            }
          }),
        );
    },
    // eslint-disable-next-line
    [API, token],
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const datasetsWasteErrorDelivery = [];
  let datasetsFillingDelivery = [];

  arrayFillingDelivery.map((fillingDelivery) => {
    datasetsFillingDelivery.push({
      label: fillingDelivery.label,
      data: [fillingDelivery.value],
      backgroundColor: [formattedFillingDeliveryColor(fillingDelivery.type)],
    });

    return null;
  });

  Object.keys(wasteErrorDelivery).forEach((waste) => {
    datasetsWasteErrorDelivery.push({
      label: waste,
      data: [wasteErrorDelivery[waste]],
      backgroundColor: [waste ? formattedWasteColor(waste) : "#6CB647"],
    });
    return null;
  });

  const componentsMap = {
    containerState: (
      <DashboardGaugeContainer
        datasetsFillingDelivery={datasetsFillingDelivery}
        setFillingRateDelivery={setFillingRateDelivery}
        arrayFillingDelivery={arrayFillingDelivery}
        setArrayFillingDelivery={setArrayFillingDelivery}
        typeContainersDelivery={typeContainersDelivery}
        setTypeContainersDelivery={setTypeContainersDelivery}
        countContainers={countContainers}
        setCountContainers={setCountContainers}
      />
    ),
    sortingError: (
      <DashboardGaugeSortingErrors
        countAnomalies={countAnomalies}
        setCountAnomalies={setCountAnomalies}
        setWasteErrorDelivery={setWasteErrorDelivery}
        datasetsWasteErrorDelivery={datasetsWasteErrorDelivery}
        wasteCategory={wasteCategory}
      />
    ),
    carbonFootprint: <DashboardGaugeCo2 />,
    characterization: (
      <DashboardGaugeCharacterization characterization={characterization} />
    ),
    onSiteVolume: (
      <DashboardGaugeOnSiteVolume
        isLoading={isLoading}
        totalVolume={totalVolume}
        totalVolumeUsed={totalVolumeUsed}
        wasteCategory={wasteCategory}
        wasteDelivery={wasteDelivery}
      />
    ),
    address: (
      <DashboardGaugeAddress isLoading={isLoading} addresses={addresses} />
    ),
  };

  const orderedComponents = displayColumns
    .filter((column) => column.selected)
    .map((column) => ({
      id: column.id,
      component: componentsMap[column.id],
    }));

  return (
    <section>
      <div className="flex items-center justify-between mt-4 mx-0">
        <DropdownSortable
          user={user}
          saveUser={saveUser}
          displayColumns={displayColumns}
          setDisplayColumns={setDisplayColumns}
          defaultDashboardConfiguration={defaultDashboardConfiguration}
        />
        <div className="flex flex-col text-end mx-3 text-grey">
          <div>
            <span className="text-cyan font-semibold">
              {licenseEndDate
                ? sinceDate(new Date(), licenseEndDate, i18n.language)
                : "-"}
            </span>
            <span className="ml-1">{t("license left")}</span>
          </div>
          {displayColumns.find((col) => col.id === "characterization")
            ?.selected && (
            <div>
              <span className="text-cyan font-semibold">
                {characterizationCredits}
              </span>
              <span className="ml-1">{t("characterization credits left")}</span>
            </div>
          )}
        </div>
      </div>

      <div className="mt-2 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3 gap-4">
        {orderedComponents.map(({ id, component }) =>
          React.cloneElement(component, { key: id }),
        )}
      </div>
    </section>
  );
};
