import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { formattedLevelBattery } from "../../components/formatted/FormattedText";
import {
  formattedDate,
  formattedTime,
} from "../../components/formatted/FormattedDateAndTime";
import CameraHeader from "./CameraHeader";
import { formattedImageName } from "../../components/formatted/FormattedAzureUrl";
import { truncateText } from "../../components/formatted/FormattedText";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";

const CameraRow = ({ data }) => {
  const { t, i18n } = useTranslation();
  const [cameraBattery, setCameraBattery] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      let cameraId = data._id;
      const imageName = formattedImageName(data.image_url, cameraId);

      if (data.isAkanthasDevice) {
        cameraId = data._id.replace("_l", "").replace("_r", "");
      }

      await axios
        .all([
          axios.get(
            `${process.env.REACT_APP_API_BACKEND}cameraLog/${cameraId}`,
            {
              headers: { authorization: `Bearer ${Cookies.get("userToken")}` },
              params: {
                imageName: imageName,
              },
            },
          ),
        ])
        .then(
          axios.spread((response) => {
            if (response.status === 200) {
              setCameraBattery(response.data.battery);
            }
          }),
        );
    };
    fetchData();
  }, [data]);

  return (
    <section className="table-block">
      <CameraHeader classColumnTable={"table-column-block"} />
      <article className="table-line">
        <div className="data-table">
          {data._id.includes("_")
            ? `CAM ${data._id.split("_").slice(-2, -1)[0].slice(-4).toUpperCase()} ${data._id.endsWith("_l") ? "Left" : "Right"}`
            : `CAM ${data._id.slice(-4).toUpperCase()}`}
        </div>

        <div className="data-table">
          <span className="data-column">
            {formattedDate(data._time, i18n.language)}
          </span>
          <span>{formattedTime(data._time)}</span>
        </div>

        <div className="data-table">
          {data?.address && data?.address.length > 0 && (
            <span>{truncateText(data?.address[0].name, 25)}</span>
          )}
        </div>

        <div className="data-table">
          {data.active ? (
            <div className="data-row">
              <div className="circle active"></div>
              <span>{t("Enabled")}</span>
            </div>
          ) : (
            <div className="data-row">
              <div className="circle not-active"></div>
              <span>{t("Disabled")}</span>
            </div>
          )}
        </div>

        <div className="data-table">
          <span>{data?.solar_panel_id}</span>
        </div>

        <div className="data-table data-row">
          {formattedLevelBattery(cameraBattery)}
          <span className="data-column">
            {cameraBattery ? `${cameraBattery}%` : "-"}
          </span>
        </div>

        <article className="data-table data-action">
          <Link className="" to={`camera/${data._id}`}>
            <i className="button-action fas fa-eye"></i>
          </Link>
        </article>
      </article>
    </section>
  );
};

export default CameraRow;
