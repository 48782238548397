import React, { useCallback, useEffect } from "react";
import axios from "axios";
import { CustomInput } from "reactstrap";
import HorizontalBarChart from "../../components/charts/HorizontalBarChart";
import { OutletPieChart } from "../../components/charts/OutletPieChart";
import { MaterialCountingPieChart } from "../../components/charts/MaterialCountingPieChart";
import {
  formattedColorMaterial,
  formattedMaterial,
} from "../../components/formatted/FormattedText";
import { sortObjectsInArrayByKeyName } from "../../components/utils/Object";
import {
  formattedDate,
  formattedTime,
} from "../../components/formatted/FormattedDateAndTime";
import CharacterisationSvg from "../../components/utils/CharacterisationSvg";
import { toFrenchNumber } from "../../components/formatted/FormattedText";
import { ImageCardComponent } from "../../components/imageCard/ImageCardComponent";
import { ContainerViewMaterialForPDF } from "./ContainerViewMaterialForPDF";
import "react-toastify/dist/ReactToastify.css";
import { indexParentCategory } from "../../shared/utils/utils";
import Loading from "../../components/utils/Loading";
import { getHistoryMaterial } from "../../services/container";
import { useTranslation } from "react-i18next";

const SingleContainerViewMaterial = ({
  data,
  expendImage,
  setExpendImage,
  setIsLoading,
  isLoading,
  multiPeriods,
  setMaterialDelivery,
  materialDelivery,
  setHistoryMaterial,
  historyMaterial,
  setParentCategoriesList,
  parentCategoriesList,
  setCharacterizationInProgress,
  characterizationInProgress,
  setTotalWasteMass,
  totalWasteMass,
  setTotalWasteVolume,
  totalWasteVolume,
  setMaterialTotal,
  materialTotal,
  setTotalMassRecoverable,
  totalMassRecoverable,
  setTotalVolumeRecoverable,
  totalVolumeRecoverable,
  periodMaterialStart,
  periodMaterialStop,
  mergeMaterialFluxes,
  mergeSimilarMaterials,
  selectedNumberOfCycleMaterial,
  historyId,
  setHistoryId,
  setDisplayMassChart,
  displayMassChart,
  setDisplayVolumeChart,
  displayVolumeChart,
  displayOutletChart,
  setDisplayOutletChart,
  setDisplayNumberOfObjectChart,
  displayNumberOfObjectChart,
  setHelpVolumeRecoverable,
  helpVolumeRecoverable,
  totalWeight,
  tableRef,
  chartRef,
  volumeRef,
  materialRef,
  setHelpMassRecoverable,
  helpMassRecoverable,
  setToogleMassChart,
  toogleMassChart,
  toogleOutletChart,
  setToogleOutletChart,
  setLoadWeight,
  formatOutletData,
  outletData,
  setOutletData,
  outletRef,
  tableOutletRef,
  toogleCharacterization,
  setToogleCharacterization,
}) => {
  const { t, i18n } = useTranslation();

  const fetchCaracterisationData = useCallback(
    () => {
      if (
        toogleCharacterization &&
        multiPeriods?.selectedList &&
        multiPeriods?.selectedList.length > 0 &&
        multiPeriods?.selectedList.some(
          (value) => value !== undefined && value !== null && value !== "",
        )
      ) {
        setIsLoading(true);
        setDisplayMassChart(true);
        setDisplayVolumeChart(false);
        setDisplayNumberOfObjectChart(false);
        setDisplayOutletChart(false);
        setLoadWeight(true);
        let selectedMultiPeriods = [];
        selectedMultiPeriods = multiPeriods.selectedList;
        const requests = selectedMultiPeriods.map((selectedPeriod) => {
          return getHistoryMaterial(
            {
              periodMaterialStart:
                selectedPeriod.periodMaterialStartSubfilter !== 0
                  ? selectedPeriod.periodMaterialStartSubfilter
                  : periodMaterialStart,
              periodMaterialStop:
                selectedPeriod.periodMaterialStopSubfilter !== 0
                  ? selectedPeriod.periodMaterialStopSubfilter
                  : periodMaterialStop,
              customDate:
                selectedPeriod.periodMaterialStopSubfilter !== 0 ? true : false,
              language: i18n.language,
            },
            data._id,
          );
        });

        axios.all(requests).then(
          axios.spread((...response) => {
            if (response[0].status === 200) {
              const _ = require("lodash");
              function multiPeriodCaracterisation(response) {
                let materialDelivery = [];
                for (let i = 0; i < selectedMultiPeriods.length; i++) {
                  for (
                    let j = 0;
                    j < response[i].data.materialDelivery.length;
                    j++
                  ) {
                    materialDelivery.push(response[i].data.materialDelivery[j]);
                  }
                }
                return [
                  ...materialDelivery
                    .reduce((r, o) => {
                      const key = o.cls;
                      const item =
                        r.get(key) ||
                        Object.assign({}, o, {
                          count: 0,
                          massObject: 0,
                          volumeObject: 0,
                          materialTotal: 0,
                          totalMassRecoverable: 0,
                          totalVolumeRecoverable: 0,
                          recoverable: false,
                        });
                      item.count += +o.count;
                      item.massObject += +o.massObject;
                      item.volumeObject += +o.volumeObject;
                      item.materialTotal += +o.materialTotal;
                      item.totalMassRecoverable += +o.totalMassRecoverable;
                      item.totalVolumeRecoverable += +o.totalVolumeRecoverable;
                      item.recoverable = o.recoverable;

                      return r.set(key, item);
                    }, new Map())
                    .values(),
                ];
              }

              const mergeConfig = JSON.parse(
                sessionStorage.getItem("materialsDescription"),
              ).map((v) => ({
                referenceName: v.referenceName,
                possibleNames: v.possibleNames,
              }));

              const concatenatedMaterials =
                multiPeriodCaracterisation(response);
              const mergedFluxes = mergeMaterialFluxes(
                concatenatedMaterials,
                mergeConfig,
              );
              const sortedFluxes = sortObjectsInArrayByKeyName(
                mergedFluxes,
                "cls",
              );
              setMaterialDelivery(sortedFluxes);

              const parentCategories = mergeSimilarMaterials(
                _.map(response, function (o) {
                  return o.data.parentCategoriesList;
                }).flat(),
              );
              const newOutletData = _.map(response, function (o) {
                return formatOutletData(o.data?.outletData, i18n.language);
              });
              const mergedOutletData = newOutletData.reduce((acc, current) => {
                if (!acc.labels) {
                  acc.labels = [...current.labels];
                }
                if (!acc.data) {
                  acc.data = [...current.data];
                } else {
                  acc.data = acc.data.map(
                    (value, index) => value + current.data[index],
                  );
                }
                acc.sum = (acc.sum || 0) + current.sum;

                return acc;
              }, {});
              setOutletData(mergedOutletData);

              const sortedParentCategoriesList = parentCategories.sort(
                (a, b) =>
                  indexParentCategory[a.parentCategory] -
                  indexParentCategory[b.parentCategory],
              );
              setParentCategoriesList(sortedParentCategoriesList);

              setHistoryMaterial(
                sortObjectsInArrayByKeyName(
                  _.map(response, function (o) {
                    return o.data.historyMaterial;
                  }).flat(1),
                  "_time",
                  "descending",
                ),
              );

              let booleanChecker = (arr) => arr.every(Boolean);
              setCharacterizationInProgress(
                booleanChecker(
                  _.map(response, function (o) {
                    return o.data.ongoingCharacterization;
                  }),
                ),
              );

              setTotalWasteMass(
                _.sumBy(response, function (o) {
                  return o.data.totalWasteMass;
                }),
              );
              setTotalWasteVolume(
                _.sumBy(response, function (o) {
                  return o.data.totalWasteVolume;
                }),
              );
              setMaterialTotal(
                _.sumBy(response, function (o) {
                  return o.data.materialTotal;
                }),
              );
              setTotalMassRecoverable(
                _.sumBy(response, function (o) {
                  return o.data.totalMassRecoverable;
                }),
              );
              setTotalVolumeRecoverable(
                _.sumBy(response, function (o) {
                  return o.data.totalVolumeRecoverable;
                }),
              );
              setIsLoading(false);
            }
          }),
        );
      } else {
        setMaterialDelivery([]);
        setHistoryMaterial([]);
        setParentCategoriesList([]);
        setCharacterizationInProgress(false);
        setTotalWasteMass(0);
        setTotalWasteVolume(0);
        setMaterialTotal(0);
        setTotalMassRecoverable(0);
        setTotalVolumeRecoverable(0);
        setOutletData({});
        setIsLoading(false);
      }
      setToogleCharacterization(false);
    },
    // eslint-disable-next-line
    [multiPeriods],
  );

  // Fetch characterization data
  useEffect(
    () => {
      fetchCaracterisationData();
    },
    // eslint-disable-next-line
    [toogleCharacterization],
  );

  const labelMaterial = [
    ...materialDelivery.map((material) =>
      formattedMaterial(material.cls, i18n.language),
    ),
  ];
  const labelMaterialSeparate = [
    ...materialDelivery.map((material) =>
      formattedMaterial(material.cls, i18n.language),
    ),
  ];
  const colorMaterial = [
    ...materialDelivery.map((material) => formattedColorMaterial(material.cls)),
  ];
  const dataVolumeMaterial = [
    ...materialDelivery.map(
      (material) => (material.volumeObject / totalWasteVolume) * 100,
    ),
  ];
  const dataNumberOfMaterial = [
    ...materialDelivery.map((material) => material.count),
  ];
  const dataMassMaterial = [
    ...materialDelivery.map(
      (material) => (material.massObject / totalWasteMass) * 100,
    ),
  ];

  return (
    <>
      {isLoading ? (
        <Loading color="#efefeffb" />
      ) : (
        // Keys & Graphs & Image
        <section className="flex flex-col xl:flex-row 2xl:flex-row bg-white rounded-md ">
          {/* Image */}
          <section
            className={`relative flex flex-col   ${expendImage ? "w-full" : "w-full xl:w-1/2 2xl:w-1/2 px-2"}`}
          >
            <section
              className={`${expendImage ? "hidden" : "absolute top-4 left-2 modal-item-key-value justify-initial"}`}
            >
              <CharacterisationSvg
                width={53}
                height={63}
                widthViewBox={22}
                heightViewBox={28}
              />
              <h5 className="font-size-50">{selectedNumberOfCycleMaterial}</h5>
              <span className="flex flex-col m-auto text-blue text-base font-semibold ml-1">
                {t("Characterization")}
                {selectedNumberOfCycleMaterial > 1 ? "s" : ""}
              </span>
            </section>

            <div>
              {historyMaterial.length > 0 && (
                <ImageCardComponent
                  container={data}
                  displayLegend={false}
                  expendImage={expendImage}
                  setExpendImage={setExpendImage}
                  history={historyMaterial}
                  historyId={historyId}
                  setHistoryId={setHistoryId}
                  isCharacterization={true}
                  displayAllCountours={true}
                />
              )}
            </div>
          </section>

          {/* Charts */}
          <section
            className={`${expendImage ? "hidden" : "flex flex-col w-full xl:w-1/2 2xl:w-1/2 bg-white px-2 my-3 relative"}`}
          >
            {/* Navigation Charts */}
            <div className="mt-2 text-cyan">
              <span
                className={`mx-1 px-2 cursor-pointer ${displayMassChart ? "bg-inherit border-solid border-cyan border-b-2" : `bg-gainsboro rounded ${!characterizationInProgress ? "hover:bg-cyan hover:text-gainsboro" : "text-cyan-transparent bg-gainsboro-transparent"}`}`}
                onClick={
                  !characterizationInProgress
                    ? () => {
                        setDisplayMassChart(true);
                        setDisplayVolumeChart(false);
                        setDisplayNumberOfObjectChart(false);
                        setDisplayOutletChart(false);
                      }
                    : null
                }
              >
                {t("Mass")}
              </span>
              <span
                className={`mx-1 px-2 cursor-pointer ${displayOutletChart ? `bg-inherit border-solid border-cyan border-b-2 ` : `bg-gainsboro rounded ${!characterizationInProgress ? "hover:bg-cyan hover:text-gainsboro" : "text-cyan-transparent bg-gainsboro-transparent"}`} `}
                onClick={() => {
                  setDisplayMassChart(false);
                  setDisplayVolumeChart(false);
                  setDisplayNumberOfObjectChart(false);
                  setDisplayOutletChart(true);
                }}
              >
                {t("Outlet")}
                {/* Filière / Exutoire */}
              </span>
              <span
                className={`mx-1 px-2 cursor-pointer ${displayNumberOfObjectChart ? `bg-inherit border-solid border-cyan border-b-2 ${characterizationInProgress ? "text-cyan-transparent border-cyan-transparent " : null}` : `bg-gainsboro rounded ${!characterizationInProgress ? "hover:bg-cyan hover:text-gainsboro" : "text-cyan-transparent bg-gainsboro-transparent"}`}`}
                onClick={
                  !characterizationInProgress
                    ? () => {
                        setDisplayMassChart(false);
                        setDisplayVolumeChart(false);
                        setDisplayNumberOfObjectChart(true);
                        setDisplayOutletChart(false);
                      }
                    : null
                }
              >
                Nb. {t("Materials")}
              </span>
              <span
                className={`mx-1 px-2 cursor-pointer ${displayVolumeChart ? `bg-inherit border-solid border-cyan border-b-2 ` : `bg-gainsboro rounded ${!characterizationInProgress ? "hover:bg-cyan hover:text-gainsboro" : "text-cyan-transparent bg-gainsboro-transparent"}`} `}
                onClick={() => {
                  setDisplayMassChart(false);
                  setDisplayVolumeChart(true);
                  setDisplayNumberOfObjectChart(false);
                  setDisplayOutletChart(false);
                }}
              >
                Volume
              </span>
            </div>

            {/* Charts */}
            <div className={`${characterizationInProgress && "blur"} `}>
              {/* Keys */}
              <div className={`modal-item-container-title `}>
                {/* Volume Chart Key */}
                {displayVolumeChart && (
                  <div className="flex justify-between w-full">
                    <article className="modal-item-key-value" />
                    <article className="modal-item-key-value relative">
                      <i className="fas fa-sync-alt font-size-35 text-gray-500 mr-3 mt-2"></i>
                      <h5 className="font-size-35">
                        {totalVolumeRecoverable > 0
                          ? toFrenchNumber(
                              (totalVolumeRecoverable / totalWasteVolume) * 100,
                              1,
                            )
                          : "0,0"}
                        %
                      </h5>
                      <article className="flex flex-col text-blue font-semibold my-auto mx-2">
                        {i18n.language === "fr" ? (
                          <>
                            <span>de matières</span>
                            <span>valorisables</span>
                          </>
                        ) : (
                          <>
                            <span>of recoverable</span>
                            <span>materials</span>
                          </>
                        )}
                      </article>
                      <i
                        className="fas fa-info-circle"
                        onMouseOver={() => setHelpVolumeRecoverable(true)}
                        onMouseOut={() => setHelpVolumeRecoverable(false)}
                      ></i>

                      {helpVolumeRecoverable ? (
                        <article className="modal-item-key-help">
                          {t("Volumetric proportion of recoverable materials")}.
                        </article>
                      ) : null}
                    </article>
                  </div>
                )}

                {/* Mass Chart Key */}
                {displayMassChart && (
                  <div className="flex flex-end w-full">
                    <article className="flex flex-col">
                      <article className="modal-item-key-value">
                        <i
                          style={{ marginTop: 9 }}
                          className="fas fa-sync-alt font-size-35 text-gray-500 mr-3 mt-2"
                        ></i>
                        <h5 style={{ fontSize: 35 }}>
                          {totalMassRecoverable > 0
                            ? toFrenchNumber(
                                (totalMassRecoverable / totalWasteMass) * 100,
                                1,
                              )
                            : "0,0"}
                          %
                        </h5>
                        <article className="flex flex-col text-blue font-semibold my-auto mx-2">
                          {i18n.language === "fr" ? (
                            <>
                              <span>de matières</span>
                              <span>valorisables</span>
                            </>
                          ) : (
                            <>
                              <span>of recoverable</span>
                              <span>materials</span>
                            </>
                          )}
                        </article>
                        <i
                          className="fas fa-info-circle"
                          onMouseOver={() => setHelpMassRecoverable(true)}
                          onMouseOut={() => setHelpMassRecoverable(false)}
                        ></i>

                        {helpMassRecoverable && (
                          <article className="modal-item-key-help">
                            {t("Mass proportion of recoverable materials")}.
                          </article>
                        )}
                      </article>
                    </article>
                  </div>
                )}
              </div>

              {/* Volume Chart */}
              {displayVolumeChart && (
                <div className="mt-16" style={{ height: "480px" }}>
                  <HorizontalBarChart
                    dataChart={dataVolumeMaterial}
                    labelsChart={labelMaterial}
                    unitValueChart="%"
                  />
                </div>
              )}

              {/* Mass Chart */}
              {displayMassChart && totalWeight !== "" && (
                <div className="mt-4">
                  <div className="flex flex-row">
                    {/* Toogle Mass Chart */}
                    <div className="flex justify-end w-full px-2 mt-2">
                      <span className="text-sm">{t("Chart")}</span>
                      <CustomInput
                        className="mx-4"
                        type="switch"
                        id="toogleMassChart"
                        defaultChecked={toogleMassChart}
                        onClick={() => setToogleMassChart(!toogleMassChart)}
                        bsSize="lg"
                        name="hazardous"
                      />
                      <span className="text-sm">{t("Table")}</span>
                    </div>
                  </div>

                  {toogleMassChart ? (
                    // Mass Chart Parent category
                    <section className="flexx flex-col my-2">
                      <div className="flex justify-end text-cyan">
                        <div className="w-1/5 px-2 mx-1 rounded-md text-center font-semibold">
                          {t("Mass")} (kg)
                        </div>
                        <div className="w-1/5 px-2 rounded-md text-center font-semibold">
                          % {t("mass")}
                        </div>
                      </div>

                      {parentCategoriesList.map(
                        (parentCategory, indexParentCategory) => {
                          return (
                            <div
                              key={`parentcat-${indexParentCategory}`}
                              className="flex justify-between text-grey"
                            >
                              <div className="flex flex-col w-3/5 bg-white-modal px-2 py-2.5 my-1 rounded-sm">
                                <div className="font-semibold">
                                  {parentCategory.parentCategory.toUpperCase()}
                                </div>
                                <div className="flex text-xs">
                                  <div>
                                    {parentCategory.categoryList
                                      .sort()
                                      .join(", ")}{" "}
                                  </div>
                                </div>
                              </div>
                              <div className="flex font-semibold justify-center items-center w-1/5 bg-white-modal px-2 py-2.5 m-1 rounded-sm ">
                                {toFrenchNumber(
                                  (parentCategory.massObjects /
                                    totalWasteMass) *
                                    (totalWeight * 1000),
                                  1,
                                )}
                              </div>
                              <div className="flex font-semibold justify-center items-center text-center w-1/5 bg-white-modal px-2 py-2.5 my-1 rounded-sm">
                                {totalWasteMass > 0
                                  ? toFrenchNumber(
                                      (parentCategory.massObjects /
                                        totalWasteMass) *
                                        100,
                                      1,
                                    )
                                  : "0,0"}
                                %
                              </div>
                            </div>
                          );
                        },
                      )}
                    </section>
                  ) : (
                    // Mass Chart Child category
                    <div className="my-3" style={{ height: "480px" }}>
                      <HorizontalBarChart
                        dataChart={dataMassMaterial}
                        labelsChart={labelMaterial}
                        unitValueChart="%"
                      />
                    </div>
                  )}
                </div>
              )}

              {/* Outlet Chart */}
              {displayOutletChart && totalWeight !== "" && (
                <div className="mt-4">
                  <div className="flex flex-row">
                    {/* Toogle Mass Chart */}
                    <div className="flex justify-end w-full px-2 mt-2">
                      <span className="text-sm">{t("Table")}</span>
                      <CustomInput
                        className="mx-4"
                        type="switch"
                        id="toogleOutletChart"
                        defaultChecked={toogleOutletChart}
                        onClick={() => setToogleOutletChart(!toogleOutletChart)}
                        bsSize="lg"
                        name="hazardous"
                      />
                      <span className="text-sm">{t("Chart")}</span>
                    </div>
                  </div>

                  {!toogleOutletChart ? (
                    // Mass Chart Parent category
                    <section className="flexx flex-col my-2">
                      <div className="flex justify-end text-cyan">
                        <div className="w-1/3 px-2 mx-1 rounded-md text-center font-semibold">
                          {t("With recycling channel")}
                        </div>
                        <div className="w-1/3 px-2 rounded-md text-center font-semibold">
                          {t("Without recycling channel")}
                        </div>
                      </div>

                      {parentCategoriesList.map(
                        (parentCategory, indexParentCategory) => {
                          return (
                            <div
                              key={`parentcat-${indexParentCategory}`}
                              className="flex justify-between text-grey"
                            >
                              <div className="flex flex-col w-1/3 bg-white-modal px-2 py-2.5 my-1 rounded-sm">
                                <div className="font-semibold">
                                  {parentCategory.parentCategory.toUpperCase()}
                                </div>
                                <div className="flex text-xs">
                                  <div>
                                    {parentCategory.categoryList
                                      .sort()
                                      .join(", ")}{" "}
                                  </div>
                                </div>
                              </div>
                              <div className="flex font-semibold justify-center items-center w-1/3 bg-white-modal px-2 py-2.5 m-1 rounded-sm ">
                                <span>
                                  {toFrenchNumber(
                                    (parentCategory.massInOutlet /
                                      totalWasteMass) *
                                      (totalWeight * 1000),
                                    1,
                                  )}{" "}
                                  kg
                                </span>
                                <span className="mx-2">/</span>
                                <span>
                                  {parentCategory.massInOutlet > 0
                                    ? toFrenchNumber(
                                        (parentCategory.massInOutlet /
                                          totalWasteMass) *
                                          100,
                                        1,
                                      )
                                    : "0,0"}
                                  %
                                </span>
                              </div>
                              <div className="flex font-semibold justify-center items-center text-center w-1/3 bg-white-modal px-2 py-2.5 my-1 rounded-sm">
                                <span>
                                  {toFrenchNumber(
                                    (parentCategory.massNotInOutlet /
                                      totalWasteMass) *
                                      (totalWeight * 1000),
                                    1,
                                  )}{" "}
                                  kg
                                </span>
                                <span className="mx-2">/</span>
                                <span>
                                  {parentCategory.massNotInOutlet > 0
                                    ? toFrenchNumber(
                                        (parentCategory.massNotInOutlet /
                                          totalWasteMass) *
                                          100,
                                        1,
                                      )
                                    : "0,0"}
                                  %
                                </span>
                              </div>
                            </div>
                          );
                        },
                      )}
                    </section>
                  ) : (
                    <div className="my-3" style={{ height: "480px" }}>
                      {outletData?.data && (
                        <OutletPieChart
                          dataMaterialChart={outletData.data}
                          labelChart={outletData.labels}
                          materialTotal={totalWasteMass}
                          colorChart={[
                            "#f58142",
                            "#42f55d",
                            "#bf42f5",
                            "#2ab5b8",
                          ]}
                          fontSizeLabel={"0.8rem"}
                          totalWeight={totalWeight}
                          size={"100%"}
                          padding={null}
                          marginLeft={-30}
                          childrenValueFontSize={"1.8rem"}
                          childrenTextFontSize={"1rem"}
                          radius={"55%"}
                          space={30}
                          pdf={false}
                        />
                      )}
                    </div>
                  )}
                </div>
              )}

              {/* Number of Objects Chart */}
              {displayNumberOfObjectChart && (
                <div
                  className="flex mx-auto justify-center relative w-full"
                  style={{ height: "80vh" }}
                >
                  {materialTotal !== undefined && materialTotal !== 0 ? (
                    <MaterialCountingPieChart
                      dataChart={dataNumberOfMaterial}
                      labelChart={labelMaterialSeparate}
                      materialTotal={materialTotal}
                      colorChart={colorMaterial}
                    />
                  ) : null}
                </div>
              )}
            </div>
            {/* No Characterization */}
            {characterizationInProgress &&
              selectedNumberOfCycleMaterial > 0 && (
                <div className="absolute inset-0 flex items-center justify-center">
                  <div className="w-2/3 bg-white px-4 py-2 shadow-sm-2 rounded-md">
                    {/* Progressive Bar */}
                    <div className="flex my-3 relative">
                      <div className="relative w-full border-solid border-gainsboro border-b-2 border-t-2 mr-4">
                        <i className="fas fa-check-circle absolute -top-3 -left-1 w-8 text-3xl text-cyan"></i>
                        <div className="bg-gainsboro py-1.5 w-4/5 rounded-md"></div>
                      </div>
                      <i className="absolute fas fa-history -top-2.5 -right-2 text-3xl text-cyan" />
                    </div>
                    {/* Image information */}
                    <div>
                      <h6 className="font-bold">
                        {t("Photo captured successfully.")}
                        <br />
                        {t("Check your photo before clearing the area.")}
                      </h6>
                      <p className="text-base">
                        {t("Characterization")}{" "}
                        {historyMaterial[historyId] !== undefined ? (
                          <>
                            {i18n.language === "fr" && "du"}{" "}
                            <span className="text-blush font-semibold">
                              {historyMaterial[historyId]._time &&
                                formattedDate(
                                  historyMaterial[historyId]._time,
                                  i18n.language,
                                  true,
                                )}{" "}
                              {t("to")}{" "}
                              {historyMaterial[historyId]._time &&
                                formattedTime(historyMaterial[historyId]._time)}
                            </span>
                          </>
                        ) : null}{" "}
                        {t("processing")}.{" "}
                        {t("Your data will be displayed soon")}.
                      </p>
                    </div>
                  </div>
                </div>
              )}
          </section>
          {!expendImage && totalWeight !== "" && (
            <ContainerViewMaterialForPDF
              tableRef={tableRef}
              parentCategoriesList={parentCategoriesList}
              totalWasteMass={totalWasteMass}
              chartRef={chartRef}
              dataMassMaterial={dataMassMaterial}
              labelMaterial={labelMaterial}
              dataMaterialChart={dataNumberOfMaterial}
              labelChart={labelMaterialSeparate}
              materialTotal={materialTotal}
              colorChart={colorMaterial}
              materialRef={materialRef}
              dataVolumeMaterial={dataVolumeMaterial}
              volumeRef={volumeRef}
              totalWeight={totalWeight * 1000}
              outletData={outletData}
              outletRef={outletRef}
              tableOutletRef={tableOutletRef}
            />
          )}
        </section>
      )}
    </>
  );
};

export default SingleContainerViewMaterial;
