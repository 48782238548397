import { useState } from "react";
import HelpOpenFile from "./HelpOpenFile";
import { t } from "i18next";

const Help = () => {
  const [isModalDownload, setIsModalDownload] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [titleModal, setTitleModal] = useState(null);

  const openFile = (fileName, title) => {
    setFileName(fileName);
    setIsModalDownload(true);
    setTitleModal(title);
  };

  return (
    <div className="base">
      <h1>{t("Help and Contact - Need assistance")} ?</h1>
      <section className="help">
        <article className="help-use">
          <h3>{t("For the use of the platform")} ?</h3>
          <article className="help-list">
            <div
              className="help-block"
              onClick={() => {
                openFile("userguide_monitoring.pdf", t("Waste monitoring"));
              }}
            >
              <i className="fas fa-laptop help-icon"></i>
              <p>{t("Waste monitoring")}</p>
            </div>
          </article>
        </article>
      </section>

      {isModalDownload ? (
        <HelpOpenFile
          isModalDownload={isModalDownload}
          setIsModalDownload={setIsModalDownload}
          titleModal={titleModal}
          fileName={fileName}
        />
      ) : null}
    </div>
  );
};

export default Help;
