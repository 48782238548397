import { Label, CustomInput, Alert } from "reactstrap";
import Loading from "../../components/utils/Loading";
import { useEffect, useState } from "react";
import { PdfDocumentViewer } from "../../components/download/PdfDocumentViewer";
import { Dropdown } from "../../components/dropdownMenu/Dropdown";
import { formatAzureBlob } from "../../components/formatted/FormattedAzureSasToken";
import { getAzureBlob, getHistoryActivity } from "../../services/container";
import ActivityHistoryTimeLineRow from "./ActivityHistoryTimeLineRow";
import { LoadingScreen } from "../../components/loader/LoadingScreen";
import { useTranslation } from "react-i18next";
import {
  capitalizeFirstLetter,
  startWithLowercase,
} from "../../components/utils/Date";
import {
  formattedMaterial,
  formattedTypeContainer,
} from "../../components/formatted/FormattedText";
import moment from "moment-timezone";
import useScrollToEnd from "../../hooks/useScrollToEnd";

const ActivityHistoryTimeLine = ({
  keywordFilter,
  periodHistoryStart,
  periodHistoryStop,
  superContainers,
  handleValidate,
  setOpenFilter,
  periodName,
}) => {
  const materials = JSON.parse(sessionStorage.getItem("materialsDescription"));
  const { t, i18n } = useTranslation();
  const [displayAlert, setDisplayAlert] = useState(false);
  const [disabledDownload, setDisabledDownload] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalDownload, setIsModalDownload] = useState(false);
  const [itemIndex, setItemIndex] = useState(null);
  const [dataHistoryContainer, setDataHistoryContainer] = useState([]);
  const [countDataHistory, setCountDataHistory] = useState(0);
  const [displayAnomaly, setDisplayAnomaly] = useState(false);
  const [dataHistoryExport, setDataHistoryExport] = useState([]);
  const [downloadProgress, setDownloadProgress] = useState(false);
  const [progress, setProgress] = useState(0);
  const [fullData, setFullData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  useScrollToEnd(() => {
    setCurrentPage((prevPage) => prevPage + 1);
  });

  useEffect(
    () => {
      setIsLoading(true);
      setOpenFilter(false);
      const fetchData = async () => {
        const response = await getHistoryActivity({
          periodStart: periodHistoryStart,
          periodStop: periodHistoryStop,
          displayAnomalie: displayAnomaly,
          keywordFilter: JSON.stringify(keywordFilter.value),
          toDownload: true,
        });
        if (response.status === 200) {
          setDataHistoryContainer(response.data.historyList);
          setFullData(response.data.historyList);
          if (response.data.historyList) {
            setCountDataHistory(response.data.historyList.length);
          }
          setIsLoading(false);
        }
      };

      fetchData();
    },

    //eslint-disable-next-line
    [handleValidate],
  );

  useEffect(
    () => {
      if (fullData.length > 0) {
        if (displayAnomaly) {
          const filteredData = fullData.filter(
            (timeLine) => timeLine.anomalies > 0,
          );
          setDataHistoryContainer(filteredData);
          setCountDataHistory(filteredData.length);
        } else {
          setDataHistoryContainer(fullData);
          setCountDataHistory(fullData.length);
        }
      }
    },
    //eslint-disable-next-line
    [displayAnomaly],
  );

  useEffect(() => {
    setTimeout(() => {
      if (displayAlert) {
        if (countDataHistory > 50 && countDataHistory < 100) {
          setDisplayAlert(false);
        }
      }
    }, 5000);
  }, [countDataHistory, displayAlert]);

  useEffect(
    () => {
      setDisplayAlert(false);
      setDisabledDownload(false);
    },
    //eslint-disable-next-line
    [periodHistoryStart, periodHistoryStop],
  );

  const closeAlert = () => setDisplayAlert(false);

  const fetchAzureData = async (history) => {
    try {
      const wasteObjects = await getAzureBlob({
        blobUrl: formatAzureBlob(
          history.image_url
            .replace("pictures", "output-surface")
            .replace("-R.jpeg", "-" + history._measurement + "-surface.json")
            .replace("-L.jpeg", "-" + history._measurement + "-surface.json"),
        ),
      });
      if (wasteObjects.status === 200) {
        history.wasteObjectsList = wasteObjects.data.object_segmentations;
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.error("Resource not found (404)");
      } else {
        console.error("Error during the request :", error.message);
      }
    }
    return history;
  };

  const updateProgress = (index, totalLength) => {
    const newProgress = ((index + 1) / totalLength) * 100;
    setProgress(newProgress);
  };

  const exportPDF = async () => {
    setDownloadProgress(true);
    setDisplayAlert(true);

    if (countDataHistory > 100) {
      setDisabledDownload(true);
    } else {
      let completedCount = 0;

      const promises = dataHistoryContainer.map((item, index) =>
        fetchAzureData(item).then((result) => {
          completedCount++;
          updateProgress(completedCount, dataHistoryContainer.length);
          return result;
        }),
      );

      const temporalDataArray = await Promise.all(promises);
      setDataHistoryExport(temporalDataArray);
      setIsModalDownload(true);
    }
    setProgress(0);
    setDownloadProgress(false);
  };

  const exportActivityToCSV = async () => {
    const materialLabel = materials
      .sort((a, b) =>
        a.frenchFormattedName.localeCompare(b.frenchFormattedName),
      )
      .map(
        (item) =>
          `${i18n.language === "en" ? item.englishFormattedName : item.frenchFormattedName}`,
      );

    const headers = [
      "Date",
      t("Address"),
      t("Container"),
      t("Filling level"),
      t("Stream"),
      t("Rotation"),
      ...materialLabel,
    ];

    const csvData = dataHistoryContainer.map((item) => {
      const formattedAnomalies = Object.entries(item.anomalyDelivery).reduce(
        (acc, [key, value]) => {
          acc[formattedMaterial(key, i18n.language)] = value;
          return acc;
        },
        {},
      );

      const anomaliesData = materialLabel.map((element) => {
        const key = element.replace(/Anomalie |Anomaly /, "");
        return formattedAnomalies[key] !== undefined
          ? formattedAnomalies[key]
          : 0;
      });

      return [
        moment(item._time).tz("Europe/Paris").format("DD-MM-YYYY HH:mm"),
        `${item.addressName}${item.positioning ? ` | ${item.positioning}` : ""}`,
        formattedTypeContainer(item.type),
        `${item.filling_level}%`,
        item.wasteName,
        item.rotation ? t("yes").toUpperCase() : t("no").toUpperCase(),
        ...anomaliesData,
      ];
    });
    const csvContent = [headers, ...csvData]
      .map((row) => row.join(";"))
      .join("\n");
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${t("Activities")} ${startWithLowercase(periodName)}.csv`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
    setProgress(0);
  };

  return (
    <div className="card-block-activity-item mt-4">
      <section className="card-block-header-history">
        <section className="relative flex">
          <article className="flex mx-2">
            <Label className="m-auto ">
              {capitalizeFirstLetter(t("sorting errors"))}
            </Label>
            <CustomInput
              className="my-auto mx-4"
              type="switch"
              id="displayAnomalie"
              defaultChecked={displayAnomaly}
              onClick={() => {
                setDisplayAnomaly(!displayAnomaly);
                setCountDataHistory(0);
              }}
              bsSize="lg"
            />
          </article>
        </section>

        <section className="flex mx-2 my-auto">
          <span className="font-semibold mr-1">
            {isLoading ? "-" : countDataHistory}
          </span>{" "}
          {countDataHistory > 1 ? t("entries") : t("entry")}
        </section>
      </section>

      <section className="flex flex-end w-full mt-2">
        <div className="flex m-auto">
          {countDataHistory === 0 && !isLoading && (
            <Alert
              className="download-alert m-auto py-1.5 modal-alert-red"
              isOpen={displayAlert}
              toggle={closeAlert}
            >
              {t("No data to download")}.
            </Alert>
          )}

          {countDataHistory > 50 && countDataHistory < 100 && (
            <Alert
              className="download-alert m-auto py-1.5 modal-alert-orange"
              isOpen={displayAlert}
              toggle={closeAlert}
            >
              {t(
                "Please note that the number of images may slow down the loading of the PDF",
              )}
              .
            </Alert>
          )}

          {countDataHistory > 100 && !isLoading && (
            <Alert
              className="download-alert m-auto py-1.5 modal-alert-red"
              isOpen={displayAlert}
              toggle={closeAlert}
            >
              {t(
                "To limit the size of the pdf, the number of images must be less than 100",
              )}
              .
            </Alert>
          )}
        </div>

        <Dropdown
          text={t("Export")}
          disabled={disabledDownload || isLoading}
          children={
            <>
              <span
                onClick={() => exportPDF()}
                className="block px-4 py-2 text-sm text-gray-500 rounded-lg hover:bg-gray-50 hover:text-gray-700 cursor-pointer"
              >
                PDF (.pdf)
              </span>
              <span
                onClick={() => exportActivityToCSV()}
                className="block px-4 py-2 text-sm text-gray-500 rounded-lg hover:bg-gray-50 hover:text-gray-700 cursor-pointer"
              >
                CSV (.csv)
              </span>
            </>
          }
        />
      </section>

      {isLoading ? (
        <Loading color="#F5F5F5" />
      ) : (
        <div>
          <section className="time-line">
            <article
              className="column-row time-line-col"
              style={{ top: "40px" }}
            >
              <h6 className="time-line-title-medium column-title">Date</h6>
              <h6 className="time-line-data-large column-title">
                {t("Address")}
              </h6>
              <h6 className="time-line-title-medium column-title">
                {t("Waste Category")}
              </h6>
              <h6 className="time-line-title-large column-title">
                {t("Filling level")}
              </h6>
              <h6 className="time-line-title column-title">{t("Packmat")}</h6>
              <h6 className="time-line-title column-title">Anomalies</h6>
              <h6 className="time-line-title-large column-title">
                {t("Container")}
              </h6>
              <h6 className="time-line-title column-title"> </h6>
            </article>
            {countDataHistory === 0 ? (
              <div className="no-data">
                Pas de données permettant d'afficher une TimeLine
              </div>
            ) : (
              <>
                <article className="time-line-list">
                  {dataHistoryContainer
                    .slice(0, currentPage * itemsPerPage)
                    .map((timeLine, index) => {
                      return (
                        <ActivityHistoryTimeLineRow
                          timeLine={timeLine}
                          index={index}
                          itemIndex={itemIndex}
                          setItemIndex={setItemIndex}
                          superContainers={superContainers}
                        />
                      );
                    })}
                </article>
              </>
            )}
          </section>

          {isModalDownload && !disabledDownload && (
            <PdfDocumentViewer
              countDataHistory={countDataHistory}
              data={dataHistoryExport}
              isModalView={isModalDownload}
              setIsModalView={setIsModalDownload}
              periodStart={periodHistoryStart}
              periodStop={periodHistoryStop}
              setDisplayAlert={setDisplayAlert}
            />
          )}

          {downloadProgress && (
            <LoadingScreen isProgress={true} progress={progress} />
          )}
        </div>
      )}
    </div>
  );
};

export default ActivityHistoryTimeLine;
