import React, { useState, useEffect, useRef } from "react";
import Cookies from "js-cookie";
import Loading from "../../components/utils/Loading";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { gsap } from "gsap";
import { Modal } from "reactstrap";
import { loadModel, createSkipWithAlert } from "./DigitalTwinModel";
import { MdLock } from "react-icons/md";

const skipsConfig = [
  {
    modelPath: "./glbFiles/Skip30m3.glb",
    position: { x: 0.8, y: 0, z: 0.2 },
    scale: { x: 0.01, y: 0.01, z: 0.01 },
    rotation: { x: 0, y: (1.15 * Math.PI) / 2, z: 0 },
  },
  {
    modelPath: "./glbFiles/Skip30m3.glb",
    position: { x: -1.6, y: 0, z: 1.8 },
    scale: { x: 0.01, y: 0.01, z: 0.01 },
    rotation: { x: 0, y: (1.15 * Math.PI) / 2, z: 0 },
  },
  {
    modelPath: "./glbFiles/Skip20m3.glb",
    position: { x: -3.8, y: 0, z: 3.4 },
    scale: { x: 0.01, y: 0.01, z: 0.01 },
    rotation: { x: 0, y: (2.15 * Math.PI) / 2, z: 0 },
  },
];

const DigitalTwin = () => {
  const token = Cookies.get("userToken");

  const skipModelsRef = useRef([]);
  const alertSkipModelsRef = useRef([]);

  const [isLoading, setIsLoading] = useState(false);
  const [view, setView] = useState("isometric"); // État pour stocker la vue actuelle
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [containerAlertStates, setContainerAlertStates] = useState(
    Array(skipsConfig.length).fill(false),
  );

  const mountRef = useRef(null);

  useEffect(() => {
    const mount = mountRef.current;
    const scene = new THREE.Scene();
    scene.background = new THREE.Color(0xf5f5f5); // Gainsboro

    const initModels = async () => {
      try {
        // Configuration des modèles d'alerte
        const alertConfig = {
          alertPath: "./glbFiles/Danger.glb",
          alertScale: { x: 0.1, y: 0.1, z: 0.1 },
        };

        // Créer les skips avec leurs modèles d'alerte associés
        const loadAllSkips = async () => {
          for (const skipConfig of skipsConfig) {
            await createSkipWithAlert(
              skipConfig,
              alertConfig,
              scene,
              skipModelsRef,
              alertSkipModelsRef,
            );
          }
        };

        loadAllSkips();

        const foxModel = await loadModel(
          "./glbFiles/SiteReze.glb",
          { x: 4, y: 0, z: -8 },
          { x: 0.01, y: 0.01, z: 0.01 },
          { x: 0, y: 1.8, z: 0 },
        );
        scene.add(foxModel);
      } catch (error) {
        console.error("Error loading models:", error);
      }
    };

    initModels();

    // Raycaster and mouse
    const raycaster = new THREE.Raycaster();
    const mouse = new THREE.Vector2();

    const onMouseMove = (event) => {
      const rect = mount.getBoundingClientRect();
      mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
      mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;
      raycaster.setFromCamera(mouse, camera);
      const intersects = raycaster.intersectObjects(scene.children, true);

      let isHovering = false;

      skipModelsRef.current.forEach((model) => {
        model.traverse((child) => {
          if (child.isMesh) {
            child.material.opacity = 1;
          }
        });

        if (
          intersects.length > 0 &&
          intersects.some((intersect) => intersect.object.parent === model)
        ) {
          model.traverse((child) => {
            if (child.isMesh) {
              isHovering = true;
              child.material.opacity = 0.3;
            }
          });
        }
      });

      // Change the cursor to pointer if hovering over a skip model
      if (isHovering) {
        document.body.style.cursor = "pointer";
      } else {
        document.body.style.cursor = "default";
      }
    };

    const onMouseClick = (event) => {
      const rect = mount.getBoundingClientRect();
      mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
      mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;
      raycaster.setFromCamera(mouse, camera);
      const intersects = raycaster.intersectObjects(scene.children, true);

      if (intersects.length > 0) {
        const firstIntersect = intersects[0];
        if (
          skipModelsRef.current.some(
            (model) => firstIntersect.object.parent === model,
          )
        ) {
          setModalIsOpen(true);
        }
      }
    };

    mount.addEventListener("mousemove", onMouseMove);
    mount.addEventListener("click", onMouseClick);

    // Définir la caméra orthographique pour une vue isométrique
    const aspect = mount.clientWidth / mount.clientHeight;
    const camera = new THREE.OrthographicCamera(
      -aspect * 5,
      aspect * 5,
      5,
      -5,
      0.1,
      1000,
    );
    //camera.position.set(200, 20, 20);
    camera.lookAt(0, 0, 0);

    // Créer le renderer et activer les ombres
    const renderer = new THREE.WebGLRenderer({ alpha: true });
    renderer.setSize(mount.clientWidth, mount.clientHeight);
    renderer.shadowMap.enabled = true;
    mount.appendChild(renderer.domElement);

    // Lumière ambiante
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.2);
    scene.add(ambientLight);

    // Ajouter une lumière directionnelle avec ombre
    const light = new THREE.DirectionalLight(0xffffff, 1);
    light.position.set(5, 5, 5);
    light.castShadow = true; // La lumière peut projeter des ombres
    light.shadow.mapSize.width = 1024;
    light.shadow.mapSize.height = 1024;
    light.shadow.camera.near = 0.1;
    light.shadow.camera.far = 100;
    scene.add(light);

    // Fonction d'animation
    const animate = () => {
      //benne2.rotation.x += 0.01;
      //scene.rotation.y += 0.0004;
      requestAnimationFrame(animate);
      renderer.render(scene, camera);
    };

    animate();

    // Ajouter les contrôles de la caméra
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true; // Permettre l'inertie
    controls.dampingFactor = 0.25; // Facteur d'amortissement
    controls.enableZoom = true; // Désactiver le zoom
    controls.enablePan = true; // Désactiver le panoramique

    // Fonction pour mettre à jour la vue
    const updateView = (view) => {
      let targetPosition;
      switch (view) {
        case "top":
          targetPosition = { x: 0, y: 10, z: 0 };
          break;
        case "isometric":
          targetPosition = { x: 10, y: 5, z: 5 };
          break;
        default:
          targetPosition = { x: 20, y: 5, z: 5 };
      }
      gsap.to(camera.position, {
        x: targetPosition.x,
        y: targetPosition.y,
        z: targetPosition.z,
        duration: 5,
        onUpdate: () => {
          camera.lookAt(0, 0, 0);
        },
      });
    };

    // Appliquer la vue initiale
    updateView(view);

    // Mettre à jour la vue lorsque l'état change
    const unsubscribe = () => {
      updateView(view);
    };
    unsubscribe();

    // Ajuster le rendu lors du redimensionnement de la fenêtre
    const handleResize = () => {
      renderer.setSize(mount.clientWidth, mount.clientHeight);
      const aspect = mount.clientWidth / mount.clientHeight;
      camera.left = -aspect * 5;
      camera.right = aspect * 5;
      camera.top = 5;
      camera.bottom = -5;
      camera.updateProjectionMatrix();
    };
    window.addEventListener("resize", handleResize);

    setIsLoading(false);

    return () => {
      window.removeEventListener("resize", handleResize);
      mount.removeChild(renderer.domElement);
    };
  }, [view]);

  const setSkipColor = (index, color) => {
    if (skipModelsRef.current[index]) {
      skipModelsRef.current[index].traverse((child) => {
        if (child.isMesh) {
          child.material.color.set(color);
        }
      });
    }
  };

  const skipAlertMode = (index, mode) => {
    if (mode === "on") {
      // Changer la couleur du skip en rouge
      setSkipColor(index, 0xff0000);

      // Activer le modèle d'alerte et démarrer l'animation de rotation
      if (alertSkipModelsRef.current[index]) {
        alertSkipModelsRef.current[index].visible =
          skipModelsRef.current[index].visible;

        // Démarrer l'animation de rotation pour le modèle d'alerte
        gsap.to(alertSkipModelsRef.current[index].rotation, {
          y: "+=6.28319", // 2*PI pour une rotation complète
          duration: 5,
          repeat: -1,
          ease: "linear",
        });
      }

      // Update the container state
      const newStates = [...containerAlertStates];
      newStates[index] = true;
      setContainerAlertStates(newStates);
    } else if (mode === "off") {
      // Réinitialiser la couleur du skip à gris
      setSkipColor(index, 0xeeeeee);

      // Rendre le modèle d'alerte invisible
      if (alertSkipModelsRef.current[index]) {
        alertSkipModelsRef.current[index].visible = false;
      }

      // Arrêter l'animation de rotation pour le modèle d'alerte
      if (alertSkipModelsRef.current[index]) {
        gsap.killTweensOf(alertSkipModelsRef.current[index].rotation);
      }

      // Update the container state
      const newStates = [...containerAlertStates];
      newStates[index] = false;
      setContainerAlertStates(newStates);
    }
  };

  const toggleModelVisibility = (index) => {
    if (skipModelsRef.current[index]) {
      skipModelsRef.current[index].visible =
        !skipModelsRef.current[index].visible;
    }

    if (
      alertSkipModelsRef.current[index] &&
      containerAlertStates[index] === true
    ) {
      alertSkipModelsRef.current[index].visible =
        !alertSkipModelsRef.current[index].visible;
    }
  };

  if (token) {
    return isLoading ? (
      <Loading color="#F5F5F5" />
    ) : (
      <div className="base">
        <h1 className="flex flex-row">
          <MdLock style={{ marginRight: "5px", height: 25, marginTop: 10 }} />
          Digital Twin
        </h1>
        <div>
          <button
            className="button-submit"
            onClick={() => skipAlertMode(0, "on")}
          >
            Start alert skip 1
          </button>
          <button
            className="button-submit"
            onClick={() => skipAlertMode(0, "off")}
          >
            Stop alert skip 1
          </button>
          <button
            className="button-submit"
            onClick={() => toggleModelVisibility(0)}
          >
            Show/Hide skip 1
          </button>
          <button className="button-submit" onClick={() => setView("top")}>
            Vue de dessus
          </button>
          <button
            className="button-submit"
            onClick={() => setView("isometric")}
          >
            Vue isométrique
          </button>
        </div>
        <div style={{ marginTop: "10px" }}>
          <button
            className="button-submit"
            onClick={() => skipAlertMode(1, "on")}
          >
            Start alert skip 2
          </button>
          <button
            className="button-submit"
            onClick={() => skipAlertMode(1, "off")}
          >
            Stop alert skip 2
          </button>
          <button
            className="button-submit"
            onClick={() => toggleModelVisibility(1)}
          >
            Show/Hide skip 2
          </button>
        </div>
        <div style={{ marginTop: "10px" }}>
          <button
            className="button-submit"
            onClick={() => skipAlertMode(2, "on")}
          >
            Start alert skip 3
          </button>
          <button
            className="button-submit"
            onClick={() => skipAlertMode(2, "off")}
          >
            Stop alert skip 3
          </button>
          <button
            className="button-submit"
            onClick={() => toggleModelVisibility(2)}
          >
            Show/Hide skip 3
          </button>
        </div>
        <div ref={mountRef} style={{ width: "100%", height: "100vh" }} />

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={() => setModalIsOpen(false)}
          contentLabel="Skip Model Modal"
          style={{
            content: {
              top: "0",
              right: "0",
              bottom: "0",
              left: "75%",
              padding: "20px",
              boxShadow: "-2px 0 5px rgba(0, 0, 0, 0.5)",
            },
          }}
        >
          <h2>Quai 3</h2>
          <button onClick={() => setModalIsOpen(false)}>Close</button>
        </Modal>
      </div>
    );
  }
};

export default DigitalTwin;
