import React, { useState } from "react";
import { Input } from "reactstrap";
import { useTranslation } from "react-i18next";
import {
  capitalizeFirstLetter,
  currentMonthName,
  previousMonthName,
} from "../../components/utils/Date";
import {
  formattedDate,
  getEndOfMonth,
  getMonthsAgo,
  getStartOfMonth,
} from "../../components/formatted/FormattedDateAndTime";

const CampaignFilter = ({
  filterRef,
  setPeriodStart,
  setPeriodStop,
  campaignPeriods,
  selectedCampaign,
  setSelectedCampaign,
  periodFilterValue,
  setPeriodFilterValue,
  setCampaignUpdate,
  setFormData,
  setCampaignDelete,
}) => {
  const { t, i18n } = useTranslation();
  const [hoveredItems, setHoveredItems] = useState(
    Array(campaignPeriods.length).fill(false),
  );

  const handleHoverChange = (index, isHovered) => {
    setHoveredItems((prevState) => {
      const newState = [...prevState];
      newState[index] = isHovered;
      return newState;
    });
  };

  const selectPeriodFilter = (value) => {
    const now = new Date();
    setPeriodFilterValue(value);
    switch (value) {
      case "0": {
        const startOfMonth = getStartOfMonth(now);
        setPeriodStart(startOfMonth.toISOString());
        setPeriodStop(now.toISOString());
        break;
      }
      case "1": {
        const startOfLastMonth = getStartOfMonth(
          new Date(now.getFullYear(), now.getMonth() - 1),
        );
        const endOfLastMonth = getEndOfMonth(
          new Date(now.getFullYear(), now.getMonth() - 1),
        );
        setPeriodStart(startOfLastMonth.toISOString());
        setPeriodStop(endOfLastMonth.toISOString());
        break;
      }
      case "-3mo":
        setPeriodStart(getMonthsAgo(now, 3).toISOString());
        setPeriodStop(now.toISOString());
        break;

      case "-6mo":
        setPeriodStart(getMonthsAgo(now, 6).toISOString());
        setPeriodStop(now.toISOString());
        break;

      case "-12mo":
        setPeriodStart(getMonthsAgo(now, 12).toISOString());
        setPeriodStop(now.toISOString());
        break;

      default:
        break;
    }
  };

  return (
    <div
      style={{
        position: "absolute",
        width: "100%",
        zIndex: 10,
        marginTop: "0.5rem",
        top: "100%",
        right: 0,
      }}
      className="flex flex-end"
    >
      <div
        ref={filterRef}
        className="flex flex-col gap-2 w-3/6 mb-2 p-2 border-2 border-[#D0D0D0] rounded-md bg-[#f8f9fa]"
      >
        <div className={`flex flex-col w-full`}>
          <span className="font-semibold">{t("Period")}</span>
          <div className="rounded-md border-2 border-[#D0D0D0]">
            <Input
              type="select"
              style={{ borderWidth: "0px" }}
              name="select-graphe"
              value={periodFilterValue}
              onChange={(event) => selectPeriodFilter(event.target.value)}
            >
              <option value="0">
                {t("Current month")} :{" "}
                {capitalizeFirstLetter(currentMonthName(i18n.language))}
              </option>
              <option value="1">
                {t("Last month")} :{" "}
                {capitalizeFirstLetter(previousMonthName(i18n.language))}
              </option>
              <option value="-3mo">{t("Last 3 months")}</option>
              <option value="-6mo">{t("Last 6 months")}</option>
              <option value="-12mo">{t("Last 12 months")}</option>
            </Input>
          </div>
        </div>
        <div>
          <span className="font-semibold">{`${t("Choisir ma campagne de tri sur")} ${campaignPeriods.length} ${t("completed")}`}</span>
          {campaignPeriods.length > 0 && (
            <div className="flex-grow overflow-y-auto w-full border-2 border-[#D0D0D0] rounded-md max-h-64">
              <ul
                className="text-md text-gray-700 dark:text-gray-200"
                aria-labelledby="dropdownCheckboxButton"
              >
                {campaignPeriods.map((item, index) => (
                  <li
                    onMouseOver={() => handleHoverChange(index, true)}
                    onMouseOut={() => handleHoverChange(index, false)}
                  >
                    <div
                      className={`w-full flex flex-row cursor-pointer hover:bg-[#00a7a6] ${selectedCampaign === index && "bg-gray-300"}`}
                    >
                      <div
                        onClick={() => setSelectedCampaign(index)}
                        className={`w-5/6 reset-margin flex items-center pt-2`}
                      >
                        <input
                          style={{ marginTop: -9 }}
                          onChange={(e) => setSelectedCampaign(index)}
                          id={`checkbox-item-${index}`}
                          type="checkbox"
                          value={item}
                          checked={selectedCampaign === index}
                          className="w-4 h-4 cursor-pointer text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                        />
                        <label
                          style={{
                            color: hoveredItems[index] ? "#FFFFFF" : "#111827",
                          }}
                          className="cursor-pointer text-md font-medium"
                        >
                          {`Campagne de tri ${`du ${formattedDate(
                            item.start,
                            i18n.language,
                            true,
                          )} au ${formattedDate(
                            item.stop,
                            i18n.language,
                            true,
                          )}`}`}
                        </label>
                      </div>
                      <div className="w-1/6 flex flex-row items-center justify-end pr-2 gap-2">
                        <i
                          className="fas fa-edit hover:text-[#f8AA00]"
                          onClick={() => {
                            setFormData(item);
                            setCampaignUpdate(true);
                          }}
                        ></i>
                        <i
                          className="fas fa-trash-alt hover:text-[#f8AA00]"
                          onClick={() => {
                            setFormData(item);
                            setCampaignDelete(true);
                          }}
                        ></i>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CampaignFilter;
