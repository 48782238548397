import { View, Page } from "@react-pdf/renderer";
import { ImageComponent } from "./ImageComponent";
import HeaderWasteQualityPDF from "./HeaderWasteQualityPDF";
import { WasteQualityLegend } from "./WasteQualityLegend";
import { WasteQualityFooter } from "./WasteQualityFooter";

export const WasteQualityImageComponent = ({
  data,
  page,
  caracDatetime,
  corporationID,
}) => {
  return (
    <Page size="A4">
      <HeaderWasteQualityPDF
        caracDatetime={caracDatetime}
        corporationID={corporationID}
      />
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          padding: 20,
          height: "79%",
          backgroundColor: "#FFFFFF",
        }}
      >
        <View
          style={{
            flex: 7.7,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <ImageComponent data={data} drawMaterial={true} />
          <ImageComponent data={data} drawMaterial={false} />
        </View>
        <View style={{ flex: 2.3 }}>
          <WasteQualityLegend data={data} />
        </View>
      </View>
      <WasteQualityFooter page={page} />
    </Page>
  );
};
