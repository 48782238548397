const externalizeImageTime = (url) => {
  const timePattern = /(\d{4}-\d{2}-\d{2}T\d{2}_\d{2}_\d{2})/;

  const searchTime = url.match(timePattern);
  if (searchTime) {
    return searchTime[0];
  }
  return null;
};

export const formattedImageName = (imageUrl, cameraId) => {
  cameraId = cameraId.replace("_l", "-L").replace("_r", "-R");
  const dateIsoFormat = externalizeImageTime(imageUrl);
  return `${dateIsoFormat}-camera-${cameraId}`;
};
