import React, { useState } from "react";
import AppHeaderBar from "./AppHeaderBar";
import AppNavBar from "./AppNavBar";
import { requestPermission } from "../firebase";
import Cookies from "js-cookie";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { LoadingScreen } from "../components/loader/LoadingScreen";
import { updateUserFcmToken } from "../services/users";
import { CustomSwitch } from "../components/CustomSwitch";

const AppSettings = () => {
  const user = JSON.parse(localStorage.getItem("user")) || null;
  const [switchValue, setSwitchValue] = useState(
    user.notificationRecipient ? true : false,
  );
  const [loading, setLoading] = useState(false);

  const handleChange = async (event) => {
    setSwitchValue(event.target.checked);
    if (event.target.checked) {
      await requestPermission(setLoading);
    } else {
      setLoading(true);
      const userAccess = await updateUserFcmToken({
        fcmToken: "",
        notificationRecipient: false,
      });
      localStorage.setItem("user", JSON.stringify(userAccess.data));
      Cookies.remove("fcmToken");
    }
    setLoading(false);
  };

  return (
    <div>
      <AppHeaderBar title="Settings" />
      <div className="mobileapp-container">
        <h1>Settings</h1>
        <div className="flex flex-col w-full gap-1 p-1 bg-white rounded-lg">
          <div className="flex justify-between items-center w-full">
            <div className="flex justify-start">
              <NotificationsIcon className="mr-1 text-[#6b7280]" />
              <span>Activer la notification push</span>
            </div>
            <CustomSwitch
              className="flex justify-end"
              sx={{ m: 1 }}
              checked={switchValue}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      {loading && <LoadingScreen />}
      <AppNavBar />
    </div>
  );
};

export default AppSettings;
