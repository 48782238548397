import React, { useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Document, Page } from "react-pdf";
import CloseIcon from "@mui/icons-material/Close";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { getCameraId } from "../../shared/utils/utils";
import {
  azureDeleteBlob,
  azureUploadFile,
  getAzureBlobFile,
  saveContainerWeight,
} from "../../services/container";
import { LoadingScreen } from "../../components/loader/LoadingScreen";
import { useTranslation } from "react-i18next";

const ActivityCollectionFileModal = ({
  data,
  openFileModal,
  setOpenFileModal,
  outputClient,
  setOutputClient,
  files,
  setFiles,
  superContainers,
  clientName,
  clientCode,
  clientAddress,
}) => {
  const { t } = useTranslation();
  const azureUrl = process.env.REACT_APP_AZURE_URL;
  const [isLoading, setIsLoading] = useState(false);
  const [pdfBlob, setPdfBlob] = useState(null);
  const [docViewer, setDocViewer] = useState(false);
  const [numPages, setNumPages] = useState();
  const [currentFile, setCurrentFile] = useState("");
  const [error, setError] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const toggleClick = () => {
    setError(null);
    if (docViewer) {
      setDocViewer(!docViewer);
    } else {
      setOpenFileModal(false);
    }
  };

  const updateOutputClient = async (newFiles, containerId, cameraId) => {
    const timestamp = data._time.replace(/:/g, "_").slice(0, -1);
    const outputClientBlob = `camera-ak_${cameraId}/output-client/${timestamp}-camera-ak_${cameraId}-${containerId}-client.json`;

    const newOutputClient = {
      ...outputClient,
      id: containerId,
      time: data._time,
      blobName: outputClientBlob,
      client: JSON.stringify({
        name: clientName,
        code: clientCode,
        address: clientAddress,
        files: newFiles,
      }),
    };

    await saveContainerWeight(newOutputClient);
    setFiles(newFiles);
    setOutputClient(newOutputClient);
  };

  const getContainerId = () =>
    Object.keys(superContainers).length > 0 ? superContainers._id : data._id;

  const getBlobName = (cameraId, containerId, fileName) => {
    const timestamp = data._time.replace(/:/g, "_").slice(0, -1);
    return `camera-ak_${cameraId}/files/${containerId}/${timestamp}/${fileName}`;
  };

  const deleteFile = async (file) => {
    setIsLoading(true);

    const newFiles = files.filter((item) => item !== file);
    const containerId = getContainerId();
    const cameraId = getCameraId(data.image_url);

    await azureDeleteBlob({ blobName: file });
    await updateOutputClient(newFiles, containerId, cameraId);

    setIsLoading(false);
  };

  const onFileChange = async (event) => {
    setError(null);
    setIsLoading(true);

    const file = event.target.files[0];
    const containerId = getContainerId();
    const cameraId = getCameraId(data.image_url);
    const blobName = getBlobName(cameraId, containerId, file.name);

    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = async () => {
      try {
        const base64File = reader.result.split(",")[1];
        const response = await azureUploadFile({ base64File, blobName });

        if (response.status === 200) {
          const newFiles = Array.from(new Set([...files, blobName]));
          await updateOutputClient(newFiles, containerId, cameraId);
        }
      } catch (uploadError) {
        if (uploadError.response.status === 413) {
          setError(uploadError.response.data.message);
        }
        console.error("File upload failed:", uploadError);
      } finally {
        setIsLoading(false);
      }
    };
  };

  const toggleDocViewer = async (file) => {
    setError(null);
    setIsLoading(true);
    setCurrentFile(file.split("/")[4]);
    try {
      const response = await getAzureBlobFile({ blobUrl: azureUrl + file });
      setPdfBlob(response.data);
      setDocViewer(true);
      setIsLoading(false);
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  const downloadFile = async () => {
    const url = window.URL.createObjectURL(pdfBlob);
    const tempLink = document.createElement("a");
    tempLink.href = url;
    tempLink.setAttribute("download", currentFile);
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
    window.URL.revokeObjectURL(url);
  };

  return (
    <Modal
      className={`${docViewer ? "w-4/6" : "w-1/3"}`}
      isOpen={openFileModal}
    >
      {error && (
        <div style={{ color: "red", marginTop: "10px" }}>Error: {error}</div>
      )}
      <ModalHeader
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <span className="text-blue">
          {docViewer ? currentFile : t("My files")}
        </span>
        <div style={{ display: "flex", gap: "10px" }}>
          {docViewer && (
            <FileDownloadIcon
              className="text-[var(--gray)] cursor-pointer"
              onClick={() => downloadFile()}
            />
          )}
          <CloseIcon
            className="text-[var(--gray)] cursor-pointer"
            onClick={() => toggleClick()}
          />
        </div>
      </ModalHeader>
      {docViewer ? (
        <div className="w-full flex justify-center bg-[#D4D4D7]">
          <Document
            file={pdfBlob}
            onLoadSuccess={onDocumentLoadSuccess}
            className=""
          >
            {Array.from(new Array(numPages), (el, index) => (
              <div key={`page_wrapper_${index + 1}`} className="my-4">
                <Page
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  width={Math.min(window.innerWidth * 0.6, 800)}
                />
              </div>
            ))}
          </Document>
        </div>
      ) : (
        <>
          <ModalBody className="flex flex-col bg-[#e5e7eb] p-2 gap-2">
            {files &&
              files.length > 0 &&
              files.map((file) => (
                <div className="flex flex-row w-full bg-white rounded-md h-10 items-center justify-between p-2">
                  <span
                    className="cursor-pointer w-11/12 overflow-hidden text-ellipsis whitespace-nowrap"
                    onClick={() => {
                      toggleDocViewer(file);
                    }}
                  >
                    <i className="fas fa-file-pdf cursor-pointer text-gray-400 mr-2"></i>
                    {file.split("/")[4]}
                  </span>
                  <i
                    className="fas fa-trash-alt hover:text-gray-400 text-red cursor-pointer"
                    onClick={() => {
                      deleteFile(file);
                    }}
                  ></i>
                </div>
              ))}
          </ModalBody>
          <ModalFooter className="bg-[#e5e7eb]">
            <div className="flex flex-row gap-3 text-blue w-full text-center text-lg justify-center cursor-pointer hover:text-[#00A7A6]">
              {isLoading ? (
                <LoadingScreen />
              ) : (
                <>
                  <label
                    htmlFor="fileInput"
                    className="flex items-center cursor-pointer"
                  >
                    <span>{t("Upload file")}</span>
                    <i className="fas fa-upload ml-2"></i>
                  </label>
                  <input
                    id="fileInput"
                    hidden
                    accept="application/pdf"
                    type="file"
                    onChange={onFileChange}
                  />
                </>
              )}
            </div>
          </ModalFooter>
        </>
      )}
    </Modal>
  );
};

export default ActivityCollectionFileModal;
