import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FaUser } from "react-icons/fa";

const AppHeaderBar = ({ title }) => {
  const headerHeight = "50px";
  const history = useHistory();

  const handleUserIconClick = () => {
    history.push("/app/account");
  };

  useEffect(() => {
    // Ajoute du padding au body pour que le contenu ne soit pas masqué par le header
    document.body.style.paddingTop = headerHeight;
    // Nettoyage pour enlever le padding si le composant est démonté
    return () => {
      document.body.style.paddingTop = null;
    };
  }, [headerHeight]);

  return (
    <div style={styles.header}>
      <span style={styles.title}>{title}</span>
      <FaUser style={styles.icon} onClick={handleUserIconClick} />
    </div>
  );
};

const styles = {
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 20px",
    backgroundColor: "#00A7A6",
    color: "white",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    position: "fixed",
    top: 0,
    width: "100%",
    zIndex: 1000,
  },
  title: {
    fontSize: "15px",
  },
  icon: {
    fontSize: "20px",
    cursor: "pointer",
  },
};

export default AppHeaderBar;
