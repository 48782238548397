import { configureStore } from "@reduxjs/toolkit";
import { containerSlice } from "./containerSlice";
import { collectionSlice } from "./collectionSlice";
import { languageSlice } from "./languageSlice";
import { notificationSlice } from "./notificationSlice";

export const store = configureStore({
  reducer: {
    containers: containerSlice.reducer,
    collection: collectionSlice.reducer,
    language: languageSlice.reducer,
    notification: notificationSlice.reducer,
  },
});
