import React, { useState, useEffect } from "react";
import axios from "axios";
import { Col, Row, Button } from "reactstrap";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardSubtitle,
} from "reactstrap";
import { Form, FormGroup, Label, Input } from "reactstrap";
import { Link } from "react-router-dom";
import Loading from "../../components/utils/Loading";
import { getSettingsData } from "../../services/settings";
import { useTranslation } from "react-i18next";

const Settings = ({ token, API }) => {
  const { t } = useTranslation();
  const [alertNumberOfDays, setAlertNumberOfDays] = useState("");
  const [contractList, setContractList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [contractNumber, setContractNumber] = useState("");
  const [contractName, setContractName] = useState("");
  const [contractCollector, setContractCollector] = useState("");
  const [collectorEmail, setCollectorEmail] = useState("");

  const fetchContractData = async () => {
    try {
      const response = await getSettingsData();
      setAlertNumberOfDays(response.data.settings.alertNumberOfDays);
      setContractList(response.data.contractList);
      setIsLoading(false);
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  useEffect(() => {
    fetchContractData();
  }, [API, token]);

  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();

    try {
      const response = await axios.post(
        `${API}settings/update`,
        {
          alertNumberOfDays,
          contractNumber,
          contractName,
          contractCollector,
          collectorEmail,
        },
        { headers: { authorization: `Bearer ${token}` } },
      );

      if (response.status === 200) {
        fetchContractData();
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  const deleteContract = async (id) => {
    setIsLoading(true);

    try {
      const response = await axios.delete(`${API}contract/delete/${id}`, {
        headers: { authorization: `Bearer ${token}` },
      });

      if (response.status === 200) {
        fetchContractData();
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  if (token) {
    return isLoading ? (
      <Loading color="#F5F5F5" />
    ) : (
      <div className="base">
        <h1> {t("My Settings")}</h1>
        <Card>
          <CardHeader>{t("Management of company constants")}</CardHeader>
          <CardBody>
            <Row>
              <Col xl={6} lg={6} md={6}>
                <Form onSubmit={handleSubmit}>
                  <FormGroup>
                    <Label>{t("Alert threshold (number of days)")}</Label>
                    <Input
                      type="number"
                      step="any"
                      value={alertNumberOfDays}
                      onChange={(event) => {
                        setAlertNumberOfDays(event.target.value);
                      }}
                    />
                  </FormGroup>

                  <p>{t("My addresses")} :</p>
                  <Link to="/address">
                    <Button className="button-main">
                      {t("Set up my addresses")}
                    </Button>
                  </Link>
                  <br />
                  <br />
                  <p>{t("My Material Categories")} :</p>
                  <Link to="/waste">
                    <Button className="button-main">
                      {t("Set up my material categories")}
                    </Button>
                  </Link>
                  <br />
                  <br />
                  <p>{t("My cameras")} :</p>
                  <Link to="/cameras">
                    <Button className="button-main">
                      {t("Set up my cameras")}
                    </Button>
                  </Link>
                  <br />
                  <br />

                  <FormGroup>
                    <Label>{t("My contracts")}</Label>

                    <Row xs="2" style={{ width: "100%", margin: "auto" }}>
                      {contractList.map((contract, index) => {
                        return (
                          <Card
                            style={{
                              width: "18rem",
                            }}
                          >
                            <CardBody>
                              <CardTitle tag="h5">
                                {contract.contract_number}
                              </CardTitle>
                              <CardSubtitle
                                className="mb-2 text-muted"
                                tag="h6"
                              >
                                {contract.name}
                              </CardSubtitle>
                              <CardSubtitle
                                className="mb-2 text-muted"
                                tag="h6"
                              >
                                {contract.collector}
                              </CardSubtitle>
                              <CardSubtitle
                                className="mb-2 text-muted"
                                tag="h6"
                              >
                                {contract.collectorEmail}
                              </CardSubtitle>
                            </CardBody>
                            <CardBody>
                              <Button
                                color="danger"
                                onClick={() => deleteContract(contract._id)}
                              >
                                {t("Delete")}
                              </Button>
                            </CardBody>
                          </Card>
                        );
                      })}
                    </Row>

                    <Card>
                      <FormGroup row>
                        <Label for="contractNumber" sm={4}>
                          {t("Contract number")}
                        </Label>
                        <Col sm={8}>
                          <Input
                            id="contractNumber"
                            name="contractNumber"
                            placeholder={t("Contract number")}
                            type="text"
                            onChange={(event) =>
                              setContractNumber(event.target.value)
                            }
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label for="contractNumber" sm={4}>
                          {t("Contract name")}
                        </Label>
                        <Col sm={8}>
                          <Input
                            id="contractNumber"
                            name="contractNumber"
                            placeholder={t("Contract name")}
                            type="text"
                            onChange={(event) =>
                              setContractName(event.target.value)
                            }
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup row>
                        <Label for="contractNumber" sm={4}>
                          {t("Collector name")}
                        </Label>
                        <Col sm={8}>
                          <Input
                            id="contractCollector"
                            name="contractCollector"
                            placeholder={t("Collector name")}
                            type="text"
                            onChange={(event) =>
                              setContractCollector(event.target.value)
                            }
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label for="contractNumber" sm={4}>
                          {t("Collection planning email")}
                        </Label>
                        <Col sm={8}>
                          <Input
                            id="collectorEmail"
                            name="collectorEmail"
                            placeholder={t("Collection planning email")}
                            type="text"
                            onChange={(event) =>
                              setCollectorEmail(event.target.value)
                            }
                          />
                        </Col>
                      </FormGroup>
                    </Card>
                    {/* <Card>
                    <Button>Ajouter Contrat</Button>
                  </Card> */}
                  </FormGroup>

                  <div>
                    <Link to="/containers">
                      <Button className="button-main">{t("Cancel")}</Button>
                    </Link>
                    <Button type="submit" color="success">
                      {t("Save")}
                    </Button>
                  </div>
                </Form>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    );
  }
};

export default Settings;
